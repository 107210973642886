var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.vatOptionsLoading,"rounded":"sm"}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-card-body',[_c('b-row',{staticClass:"m-0 mb-2"},[_c('b-col',{staticClass:"p-0",attrs:{"md":"6"}},[_c('span',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.$t("students.new_payment")))])]),_c('b-col',{staticClass:"p-0 d-flex justify-content-end align-items-center",attrs:{"md":"6"}},[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t("general.payment")))]),_c('b-form-checkbox',{staticClass:"payment-refund-switch align-self-baseline",attrs:{"switch":"","size":"lg"},model:{value:(_vm.costBearersPaymentData.refund),callback:function ($$v) {_vm.$set(_vm.costBearersPaymentData, "refund", $$v)},expression:"costBearersPaymentData.refund"}}),_c('span',[_vm._v(_vm._s(_vm.$t("user.repayment")))])],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("user.remark"))+" ")]),_c('validation',{attrs:{"validations":_vm.v.costBearersPaymentData.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{class:{ 'is-invalid': invalid },attrs:{"type":"text"},model:{value:(_vm.costBearersPaymentData.description),callback:function ($$v) {_vm.$set(_vm.costBearersPaymentData, "description", $$v)},expression:"costBearersPaymentData.description"}})}}])})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("calendar.form_date"))+" * ")]),_c('validation',{attrs:{"validations":_vm.v.costBearersPaymentData.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"initial-date":_vm.initialDate,"label-no-date-selected":_vm.$t('calendar.label_no_date'),"label-help":_vm.$t('calendar.label_help')},model:{value:(_vm.costBearersPaymentData.date),callback:function ($$v) {_vm.$set(_vm.costBearersPaymentData, "date", $$v)},expression:"costBearersPaymentData.date"}})}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("students.payment_method"))+" * ")]),_c('validation',{attrs:{"validations":_vm.v.costBearersPaymentData.paymentMethod},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.paymentMethodOptions,"label":"name","track-by":"id"},model:{value:(_vm.costBearersPaymentData.paymentMethod),callback:function ($$v) {_vm.$set(_vm.costBearersPaymentData, "paymentMethod", $$v)},expression:"costBearersPaymentData.paymentMethod"}})}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("students.amount"))+" ")]),_c('b-input-group',[_c('validation',{attrs:{"validations":_vm.v.costBearersPaymentData.amountInEur},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('FscInputDecimal',{class:{ 'is-invalid': invalid },model:{value:(_vm.costBearersPaymentData.amountInEur),callback:function ($$v) {_vm.$set(_vm.costBearersPaymentData, "amountInEur", $$v)},expression:"costBearersPaymentData.amountInEur"}})}}])})],1)],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"md":"12"}},[_c('CostBearerPaymentsByStudentEducation',{attrs:{"amount":_vm.costBearersPaymentData.amountInEur,"customers-with-educations":_vm.customersWithEducations,"unpaid-invoices":_vm.unpaidInvoices},on:{"update:amount":function($event){return _vm.$set(_vm.costBearersPaymentData, "amountInEur", $event)}}})],1),_c('b-col',{staticClass:"p-0 pt-3 d-flex justify-content-end align-items-end",attrs:{"md":"12"}},[_c('abort-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":""},on:{"click":_vm.onAbort}}),_c('save-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":_vm.$t('general.save').toString(),"disabled":_vm.controlSum !== 0,"loading":_vm.putLoading},on:{"click":_vm.onSubmit}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }