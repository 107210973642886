




















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FullCalendar, { CalendarOptions, ViewApi } from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import FormCard from "@/components/FormCard.vue";
import moment from "moment";
import CustomEvent from "@/views/Scheduler/CustomEvent.vue";
import { ICalendarTime } from "@/interfaces/ICalendarTime";
import { PropType } from "vue";
import UserService from "@/services/UserService";
import { formatInstructorName } from "@/utils/NameUtil";
import { Calendar as FullCalendarType } from "@fullcalendar/vue";

@Component({
  components: {
    CustomEvent,
    FormCard,
    FullCalendar,
  },
})
export default class Calendar extends Vue {
  public name = "Calendar";

  @Prop({ default: () => false, type: Boolean })
  public isLoading!: boolean;

  @Prop()
  public user!: any;

  @Prop()
  public allowDrop!: boolean;

  @Prop({ type: Array as PropType<Array<ICalendarTime>>, default: () => [] })
  public calendarNightTimes!: Array<ICalendarTime>;

  public acceptDropFlag = true;

  private get schedulerLicenseKey() {
    return process.env.VUE_APP_FULLCALENDAR_KEY;
  }

  public calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, resourceTimeGridPlugin],
    schedulerLicenseKey: this.schedulerLicenseKey,
    initialView: this.getInitialView,
    headerToolbar: {
      left: "prev,today,next",
      center: "title",
      right: "dayGridMonth,timeGridWeek,resourceTimeGridDay",
    },
    buttonText: {
      today: this.$t("calendar.today").toString(),
      dayGridMonth: this.$t("calendar.month").toString(),
      timeGridWeek: this.$t("calendar.week").toString(),
      timeGridDay: this.$t("calendar.day").toString(),
      resourceTimeGridDay: this.$t("calendar.day").toString(),
    },
    allDayText: this.$t("calendar.all_day").toString(),
    selectable: true,
    select: this.handleDateSelect,
    eventClick: this.handleEventClick,
    nowIndicator: true,
    locale: "de",
    hiddenDays: [],
    firstDay: 1,
    navLinks: true,
    weekNumbers: true,
    viewDidMount: (info: { el: HTMLElement; view: ViewApi }) => {
      this.setInitialUser(this.currentUser.id);
      this.setInitialView(info.view.type);
      if (info.view.type == "resourceTimeGridDay") {
        const el: HTMLElement | null = info.el.querySelector(".fc-timegrid-axis-frame");
        if (el) {
          const w = moment(info.view.currentStart).format("W");
          const r = `<div class="fc-timegrid-axis-cushion">
                        <div class="fc-dayweek-day d-flex align-items-baseline justify-content-center">
                            <div class="fc-daygrid-day-number">W ${w}</div>
                        </div>
                   </div>`;
          el.classList.add("fc-scrollgrid-shrink-frame", "fc-timegrid-axis-frame-liquid");
          el.innerHTML = r;
        }
      }
    },
    weekNumberContent: (ctx: any) => {
      return {
        html: `<div class="fc-dayweek-day d-flex align-items-baseline justify-content-center">
                  <div class="fc-daygrid-day-number">${ctx.text}</div>
               </div>`,
      };
    },
    slotDuration: "00:15:00",
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      omitZeroMinute: false,
      meridiem: false,
    },
    dayHeaderFormat: {
      weekday: "short",
      day: "numeric",
      omitCommas: true,
    },
    dayHeaderContent: (ctx: any) => {
      const weekday = moment(ctx.date).locale("de").format("dd").toUpperCase();

      if (ctx.view.type === "timeGridWeek") {
        const day = moment(ctx.date).locale("de").format("D");

        return {
          html: `<div class="fc-dayweek-day d-flex align-items-baseline justify-content-start">
                        <div class="fc-daygrid-day-number">${day}</div>
                         <div class="text-dark-today">${weekday}</div>
                    </div>`,
        };
      } else if (ctx.view.type === "dayGridMonth") {
        return {
          html: weekday,
        };
      }

      return {
        html: `<span id="user-name">${this.fullName}</span>`,
      };
    },
    eventSources: [
      {
        events: this.eventsHandle,
      },
    ],
    lazyFetching: false,
    handleWindowResize: true,
    editable: true, //TODO enable when editing events is implemented
    eventDurationEditable: false,
    droppable: true,
    scrollTime: "7:00",
    scrollTimeReset: false,
    eventAllow: this.handleEventDropAccept,
    eventDragStart: this.handleEventDragStart,
    eventDrop: this.handleEventDrop,
    eventMouseEnter: function (mouseEnterInfo) {
      if ("dayGridMonth" !== mouseEnterInfo.view.type) {
        const el: HTMLElement = mouseEnterInfo.el;
        const currentHeight = el.clientHeight;

        const timeEls = el.getElementsByClassName("fc-event-time");
        const titleEls = el.getElementsByClassName("fc-event-title");

        let height = 4;

        for (const el of timeEls) {
          height += el.clientHeight;
        }

        for (const el of titleEls) {
          height += el.clientHeight;
        }

        if (currentHeight < height) {
          mouseEnterInfo.el.style.height = `${height}px`;
        }
      }
    },
    eventMouseLeave: function (mouseEnterInfo) {
      if ("dayGridMonth" !== mouseEnterInfo.view.type) {
        mouseEnterInfo.el.style.height = ``;
      }
    },
    resources: [],
    allDaySlot: true,
  };
  protected oldEventCtx: any = null;

  public handleDateSelect(ctx: any): void {
    this.removeSelectedHighlight();
    // Sending ctx to Scheduler.
    this.$emit("calendar-click", ctx);
  }

  public handleEventClick(ctx: any): void {
    this.removeSelectedHighlight();
    ctx.el.classList.add("fc-event-selected");
    this.$emit("event-click", ctx);
    this.oldEventCtx = ctx;
  }

  public removeSelectedHighlight(): void {
    if (this.oldEventCtx) {
      this.oldEventCtx.el.classList.remove("fc-event-selected");
    }
  }

  public eventsHandle(ctx: any, successCallback: any, failureCallback: any): void {
    this.$emit("handle-events", {
      ctx,
      successCallback,
      failureCallback,
    });
  }

  public isNight(ctx: any): boolean {
    const reformatTimeToFullHour = moment(ctx.text, "HH:mm").format("HH:mm:ss");
    const found = this.calendarNightTimes.find((x) => x.fullhour === reformatTimeToFullHour);
    return Boolean(found && found.night);
  }

  private get getApi(): FullCalendarType {
    return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi();
  }

  public refetchEvents(): void {
    this.getApi.refetchEvents();
  }

  public renderCalendar(): void {
    this.getApi.render();
  }

  protected get fullName(): string {
    if (!this.user) return "";
    return formatInstructorName(this.user.firstName, this.user.lastName);
  }

  public setOption(name: any, value: any): void {
    this.getApi.setOption(name, value);
  }

  public getOption(name: any): any {
    return this.getApi.getOption(name);
  }

  public getCurrentView(): ViewApi {
    return this.getApi.view;
  }

  public handleEventDropAccept(): boolean {
    this.$root.$on("allow-drop", (dropFlag: any) => {
      this.acceptDropFlag = dropFlag;
    });
    return this.acceptDropFlag && this.allowDrop;
  }
  public handleEventDragStart(info: any): void {
    this.$emit("on-drag-start", info);
  }
  public handleEventDrop(info: any): void {
    this.$emit("on-drop", info);
  }

  public setInitialView(view: any) {
    localStorage.setItem("initialView", String(view));
  }

  public get getInitialView() {
    const view = localStorage.getItem("initialView");
    const initialUser = localStorage.getItem("initialUser");
    if (view && initialUser == this.currentUser.id) {
      return view;
    }
    return "timeGridWeek";
  }

  public get currentUser(): any {
    return UserService.getUser();
  }

  public setInitialUser(userId: any) {
    localStorage.setItem("initialUser", userId);
  }
}
