var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',{staticClass:"py-3"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(" "+_vm._s(_vm.$t("students.payers"))+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"2"}},[_c('profile-photo',{attrs:{"placeholder-icon":"building","width":"45","height":"45","url":_vm.profileUrl,"disabled":_vm.hasNotPermissionWrite},on:{"file":_vm.onUploadFile}})],1)],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("students.company"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.bearer.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.name),callback:function ($$v) {_vm.$set(_vm.bearer, "name", $$v)},expression:"bearer.name"}})}}])})],1)],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("general.contact_person")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.contactPerson),callback:function ($$v) {_vm.$set(_vm.bearer, "contactPerson", $$v)},expression:"bearer.contactPerson"}})],1),_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.email"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.bearer.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.email),callback:function ($$v) {_vm.$set(_vm.bearer, "email", $$v)},expression:"bearer.email"}})}}])})],1)],1),_c('b-row',{staticClass:"pb-3 pt-4"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(_vm._s(_vm.$t("students.address_data")))])])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.city"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.bearer.location},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.location),callback:function ($$v) {_vm.$set(_vm.bearer, "location", $$v)},expression:"bearer.location"}})}}])})],1),_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.post_code"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.bearer.postalCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.postalCode),callback:function ($$v) {_vm.$set(_vm.bearer, "postalCode", $$v)},expression:"bearer.postalCode"}})}}])})],1),_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.street_no"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.bearer.address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('b-form-input',{staticClass:"mt-1",class:{ 'is-invalid': invalid },attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.address),callback:function ($$v) {_vm.$set(_vm.bearer, "address", $$v)},expression:"bearer.address"}})}}])})],1)],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.telephone")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.phone),callback:function ($$v) {_vm.$set(_vm.bearer, "phone", $$v)},expression:"bearer.phone"}})],1),_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.telephone_private")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.privatePhone),callback:function ($$v) {_vm.$set(_vm.bearer, "privatePhone", $$v)},expression:"bearer.privatePhone"}})],1),_c('b-col',[_c('label',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("user.fax")))]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.fax),callback:function ($$v) {_vm.$set(_vm.bearer, "fax", $$v)},expression:"bearer.fax"}})],1)],1),_c('b-row',{staticClass:"pb-3 pt-4"},[_c('b-col',[_c('span',{staticStyle:{"font-weight":"bold","font-size":"large"}},[_vm._v(_vm._s(_vm.$t("students.payment_data")))])])],1),_c('b-row',[_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("students.payment_method"))+"*")]),_c('validation',{attrs:{"validations":_vm.$v.selectedPaymentWorkFlow},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{staticClass:"mt-1",class:{ multiselect__invalid: invalid },attrs:{"allow-empty":false,"label":"name","track-by":"id","showLabels":false,"options":_vm.paymentWorkFlowOptions,"placeholder":_vm.$t('calendar.option_placeholder'),"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.selectedPaymentWorkFlow),callback:function ($$v) {_vm.selectedPaymentWorkFlow=$$v},expression:"selectedPaymentWorkFlow"}})}}])})],1),_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("user.email"))+" ( "+_vm._s(_vm.$t("user.invoice_recipient"))+" ) ")]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.invoiceEmail),callback:function ($$v) {_vm.$set(_vm.bearer, "invoiceEmail", $$v)},expression:"bearer.invoiceEmail"}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.$tc("calendar.other", 1))+"/"+_vm._s(_vm.$t("general.info"))+" ")]),_c('b-form-input',{staticClass:"mt-1",attrs:{"disabled":_vm.hasNotPermissionWrite},model:{value:(_vm.bearer.info),callback:function ($$v) {_vm.$set(_vm.bearer, "info", $$v)},expression:"bearer.info"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end ml-2 mt-4"},[_c('abort-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":""},on:{"click":_vm.onAbort}}),_c('save-button',{staticClass:"ml-2 px-3 fsc-btn-border",attrs:{"label":_vm.$t('general.save'),"disabled":_vm.hasNotPermissionWrite,"loading":_vm.loading},on:{"click":_vm.onSubmit}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }