




































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import ProfilePhoto from "@/views/Student/ProfilePhoto.vue";
import { mixins } from "vue-class-component";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import PaymentWorkflowRequestMixin from "@/mixins/Request/PaymentWorkflowRequestMixin";

@Component({
  components: {
    ProfilePhoto,
    FscSimpleCard,
    SaveButton,
    AbortButton,
    Validation,
  },
})
export default class CostBearersForm extends mixins(CostBearerMixin, PaymentWorkflowRequestMixin) {
  public name = "CostBearersForm";

  @Prop()
  public costBearerData: any;

  @Prop({ type: String, default: () => "" })
  public profileUrl!: string;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  protected logoFile: File | null = null;

  public bearer = {
    name: "",
    contactPerson: "",
    email: "",
    postalCode: "",
    location: "",
    address: "",
    phone: "",
    privatePhone: "",
    fax: "",
    paymentWorkflowId: null,
    invoiceEmail: "",
    info: "",
  };

  public selectedPaymentWorkFlow: any = [];

  public mounted(): void {
    this.fetchPaymentWorkflows();
  }

  private get allPaymentWorkflows() {
    return this.paymentWorkflows;
  }

  private async fetchPaymentWorkflows() {
    await this.findAllPaymentWorkflows();
    this.selectedPaymentWorkFlow = this.paymentWorkFlowOptions;
  }

  @Watch("costBearerData", { deep: true, immediate: true })
  private onChangeCostBearer(costBearerData: any) {
    if (costBearerData) {
      this.bearer = costBearerData;
    }
  }

  public get paymentWorkFlowOptions(): Array<any> {
    if (!this.allPaymentWorkflows) {
      return [];
    }
    return this.allPaymentWorkflows.filter((payment: any) => payment.id === 1);
  }

  public onAbort(): void {
    this.$emit("on-abort");
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    const data = {
      name: this.bearer.name,
      contactPerson: this.bearer.contactPerson,
      email: this.bearer.email,
      postalCode: this.bearer.postalCode,
      location: this.bearer.location,
      address: this.bearer.address,
      phone: this.bearer.phone,
      privatePhone: this.bearer.privatePhone,
      fax: this.bearer.fax,
      paymentWorkflowId: this.selectedPaymentWorkFlow[0].id,
      invoiceEmail: this.bearer.invoiceEmail,
      info: this.bearer.info,
    };
    this.$emit("on-submit", {
      bearer: data,
      file: this.logoFile,
    });
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      bearer: {
        name: { required },
        email: { required, email },
        postalCode: { required },
        location: { required },
        address: { required },
      },
      selectedPaymentWorkFlow: { required },
    };
  }

  public onUploadFile(file: File): void {
    this.logoFile = file;
  }
}
