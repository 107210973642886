




import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class CostBearerPaymentsByUnpaidInvoices extends Vue {
  public name = "CostBearerPaymentsByUnpaidInvoices";

  @Prop({ default: () => [] })
  private unpaidInvoices!: any;
}
