import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IVehicle } from "@/interfaces/IVehicle";

const URL = "vehicles";

@Component
export default class VehicleRequestMixin extends Vue {
  public vehiclesByInstructorAndUsageAndLicenseClass: Array<IVehicle> = [];
  public vehiclesByInstructorAndUsageAndLicenseClassLoading = false;
  public vehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
  public vehiclesByInstructorAndUsageAndLicenseClassError: Record<any, any> | null = null;

  public escortVehiclesByInstructorAndUsageAndLicenseClass: Array<IVehicle> = [];
  public escortVehiclesByInstructorAndUsageAndLicenseClassLoading = false;
  public escortVehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
  public escortVehiclesByInstructorAndUsageAndLicenseClassError: Record<any, any> | null = null;

  public trailerVehiclesByInstructorAndUsageAndLicenseClass: Array<IVehicle> = [];
  public trailerVehiclesByInstructorAndUsageAndLicenseClassLoading = false;
  public trailerVehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
  public trailerVehiclesByInstructorAndUsageAndLicenseClassError: Record<any, any> | null = null;

  public lastUsedVehicle: any = null;

  private async request(instructorId: number, vehicleUsage: string, licenseClass: string): Promise<any> {
    return await axios.get(`${URL}/instructor/${instructorId}/usage/${vehicleUsage}/license-class/${licenseClass}`);
  }

  public async fetchVehiclesByInstructorAndUsageAndLicenseClass(instructorId: number, vehicleUsage: string, licenseClass: string): Promise<void> {
    this.vehiclesByInstructorAndUsageAndLicenseClassLoading = true;
    this.vehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
    this.vehiclesByInstructorAndUsageAndLicenseClassError = null;

    return await this.request(instructorId, vehicleUsage, licenseClass)
      .then((response: AxiosResponse) => {
        this.vehiclesByInstructorAndUsageAndLicenseClass = response.data;
        this.vehiclesByInstructorAndUsageAndLicenseClassSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.vehiclesByInstructorAndUsageAndLicenseClassError = error.response?.data;
      })
      .finally(() => {
        this.vehiclesByInstructorAndUsageAndLicenseClassLoading = false;
      });
  }

  public async fetchEscortVehiclesByInstructorAndUsageAndLicenseClass(instructorId: number, vehicleUsage: string, licenseClass: string): Promise<void> {
    this.escortVehiclesByInstructorAndUsageAndLicenseClassLoading = true;
    this.escortVehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
    this.escortVehiclesByInstructorAndUsageAndLicenseClassError = null;

    return await this.request(instructorId, vehicleUsage, licenseClass)
      .then((response: AxiosResponse) => {
        this.escortVehiclesByInstructorAndUsageAndLicenseClass = response.data;
        this.escortVehiclesByInstructorAndUsageAndLicenseClassSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.escortVehiclesByInstructorAndUsageAndLicenseClassError = error.response?.data;
      })
      .finally(() => {
        this.escortVehiclesByInstructorAndUsageAndLicenseClassLoading = false;
      });
  }

  public async fetchTrailerVehiclesByInstructorAndUsageAndLicenseClass(instructorId: number, vehicleUsage: string, licenseClass: string): Promise<void> {
    this.trailerVehiclesByInstructorAndUsageAndLicenseClassLoading = true;
    this.trailerVehiclesByInstructorAndUsageAndLicenseClassSuccess = false;
    this.trailerVehiclesByInstructorAndUsageAndLicenseClassError = null;

    return await this.request(instructorId, vehicleUsage, licenseClass)
      .then((response: AxiosResponse) => {
        this.trailerVehiclesByInstructorAndUsageAndLicenseClass = response.data;
        this.trailerVehiclesByInstructorAndUsageAndLicenseClassSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.trailerVehiclesByInstructorAndUsageAndLicenseClassError = error.response?.data;
      })
      .finally(() => {
        this.trailerVehiclesByInstructorAndUsageAndLicenseClassLoading = false;
      });
  }

  public async fetchLastUsedVehicleByStudentEducationAndInstructor({ educationId, instructorId }: any): Promise<any> {
    return await axios
      .get(`/vehicles/last-used-vehicle/${educationId}/${instructorId}`)
      .then((response: AxiosResponse) => {
        this.lastUsedVehicle = response.data;
        return response.data;
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  }
}
