












import { Component, Prop, Vue } from "vue-property-decorator";
import CostBearersForm from "@/views/CostBearers/CostBearersForm.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: {
    CostBearersForm,
    FscSimpleCard,
  },
})
export default class CostBearerEdit extends Vue {
  public name = "CostBearerEdit";

  @Prop()
  public costBearer: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  protected logoUrl = "/cost-bearers/logo-image";

  private initialCostBearer = {
    name: "",
    contactPerson: "",
    email: "",
    postalCode: "",
    location: "",
    address: "",
    phone: "",
    privatePhone: "",
    fax: "",
    paymentWorkflowId: null,
    invoiceEmail: "",
    info: "",
  };

  public onCostBearerUpdate(data: { bearer: any; file: File | null }): void {
    this.$emit("update", data);
  }

  public onCostBearerAbortUpdate(): void {
    this.$emit("close-update");
  }

  public get selectedCostBearer() {
    return this.costBearer || this.initialCostBearer;
  }

  protected get profileUrl(): string {
    if (!this.selectedCostBearer) return "";
    return `${this.logoUrl}?costBearerId=${this.selectedCostBearer.id}`;
  }
}
