import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import createCancelableRequest from "@/utils/axiosCancelable";

@Component
export default class CostBearerRequestMixin extends Vue {
  private allCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public costBearers: Array<any> = [];
  public costBearersSuccess = false;
  public costBearersError: Record<any, any> | null = null;
  private costBearersLoadingQueue = 0;

  public get costBearersLoading() {
    return this.costBearersLoadingQueue > 0;
  }

  public async findAllCostBearers(): Promise<void> {
    this.costBearersLoadingQueue++;
    this.costBearersSuccess = false;
    this.costBearersError = null;

    return await this.allCostBearerRequest
      .makeRequest({
        method: "get",
        url: `/cost-bearers/`,
      })
      .then((response: AxiosResponse) => {
        this.costBearers = response.data;
        this.costBearersSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearersError = error.response?.data;
      })
      .finally(() => {
        this.costBearersLoadingQueue--;
      });
  }

  public costBearerPaymentRequestResponse: any = null;
  public costBearerPaymentRequestError: any = null;
  public costBearerPaymentRequestLoading: any = false;
  public costBearerPaymentRequestSuccess: any = false;

  public async fetchCostBearerPaymentRequest(id: number): Promise<void> {
    this.costBearerPaymentRequestError = null;
    this.costBearerPaymentRequestSuccess = false;
    this.costBearerPaymentRequestLoading = true;
    return await axios
      .get(`cost-bearer-payment-requests/${id}`)
      .then((response: AxiosResponse) => {
        this.costBearerPaymentRequestSuccess = true;
        this.costBearerPaymentRequestResponse = response.data;
      })
      .catch((error: AxiosError) => {
        this.costBearerPaymentRequestError = error.response?.data;
      })
      .finally(() => {
        this.costBearerPaymentRequestLoading = false;
      });
  }

  // filter cost bearers
  private filterCostBearersRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public filterCostBearers: Array<any> = [];
  public filterCostBearersTotal = 0;
  public filterCostBearersSuccess = false;
  public filterCostBearersError: Record<any, any> | null = null;
  private filterCostBearersLoadingQueue = 0;

  public get filterCostBearersLoading() {
    return this.filterCostBearersLoadingQueue > 0;
  }

  public async filterCostBearersAction(filter: any): Promise<void> {
    this.filterCostBearersLoadingQueue++;
    this.filterCostBearersSuccess = false;
    this.filterCostBearersError = null;

    return await this.allCostBearerRequest
      .makeRequest({
        method: "post",
        url: `/cost-bearers/filter`,
        data: filter,
      })
      .then((response: AxiosResponse) => {
        this.filterCostBearers = response.data?.data || [];
        this.filterCostBearersTotal = response.data?.total || 0;
        this.filterCostBearersSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.filterCostBearersError = error.response?.data;
      })
      .finally(() => {
        this.filterCostBearersLoadingQueue--;
      });
  }

  // create cost bearer
  private createCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public createdCostBearerId = 0;
  public createCostBearerSuccess = false;
  public createCostBearerError: Record<any, any> | null = null;
  private createCostBearerLoadingQueue = 0;

  public get createCostBearerLoading() {
    return this.createCostBearerLoadingQueue > 0;
  }

  public async createCostBearerAction(data: any): Promise<void> {
    this.createCostBearerLoadingQueue++;
    this.createCostBearerSuccess = false;
    this.createCostBearerError = null;

    return await this.createCostBearerRequest
      .makeRequest({
        method: "post",
        url: `/cost-bearers`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.createdCostBearerId = response.data;
        this.createCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.createCostBearerLoadingQueue--;
      });
  }

  // upload cost bearer file
  private uploadCostBearerFileRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public uploadCostBearerFileSuccess = false;
  public uploadCostBearerFileError: Record<any, any> | null = null;
  private uploadCostBearerFileLoadingQueue = 0;

  public get uploadCostBearerFileLoading() {
    return this.uploadCostBearerFileLoadingQueue > 0;
  }

  public async uploadCostBearerFileAction(costBearerId: number, data: FormData): Promise<void> {
    this.uploadCostBearerFileLoadingQueue++;
    this.uploadCostBearerFileSuccess = false;
    this.uploadCostBearerFileError = null;

    return await this.uploadCostBearerFileRequest
      .makeRequest({
        method: "post",
        url: `/cost-bearers/logo-image?costBearerId=${costBearerId}`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.uploadCostBearerFileSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.uploadCostBearerFileError = error.response?.data;
      })
      .finally(() => {
        this.uploadCostBearerFileLoadingQueue--;
      });
  }

  // update cost bearer
  private updateCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public updateCostBearerSuccess = false;
  public updateCostBearerError: Record<any, any> | null = null;
  private updateCostBearerLoadingQueue = 0;

  public get updateCostBearerLoading() {
    return this.updateCostBearerLoadingQueue > 0;
  }

  public async updateCostBearerAction(costBearerId: number, data: any): Promise<void> {
    this.updateCostBearerLoadingQueue++;
    this.updateCostBearerSuccess = false;
    this.updateCostBearerError = null;

    return await this.updateCostBearerRequest
      .makeRequest({
        method: "put",
        url: `/cost-bearers/${costBearerId}`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.updateCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.updateCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.updateCostBearerLoadingQueue--;
      });
  }

  // get cost bearer
  private getCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public costBearerSuccess = false;
  public costBearer = null;
  public costBearerError: Record<any, any> | null = null;
  private costBearerLoadingQueue = 0;

  public get costBearerLoading() {
    return this.costBearerLoadingQueue > 0;
  }

  public async getCostBearerAction(costBearerId: number): Promise<void> {
    this.costBearerLoadingQueue++;
    this.costBearerSuccess = false;
    this.costBearerError = null;

    return await this.getCostBearerRequest
      .makeRequest({
        method: "get",
        url: `/cost-bearers/${costBearerId}`,
      })
      .then((response: AxiosResponse) => {
        this.costBearer = response.data;
        this.costBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerError = error.response?.data;
      })
      .finally(() => {
        this.costBearerLoadingQueue--;
      });
  }

  // student education by cost bearer
  private studentEducationsByCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public studentEducationsByCostBearerSuccess = false;
  public studentEducationsByCostBearer = [];
  public studentEducationsByCostBearerError: Record<any, any> | null = null;
  private studentEducationsByCostBearerLoadingQueue = 0;

  public get studentEducationsByCostBearerLoading() {
    return this.studentEducationsByCostBearerLoadingQueue > 0;
  }

  public async studentEducationsByCostBearerAction(costBearerId: number): Promise<void> {
    this.studentEducationsByCostBearerLoadingQueue++;
    this.studentEducationsByCostBearerSuccess = false;
    this.studentEducationsByCostBearerError = null;

    return await this.studentEducationsByCostBearerRequest
      .makeRequest({
        method: "get",
        url: `/cost-bearers/student-education-by-cost-bearer/${costBearerId}`,
      })
      .then((response: AxiosResponse) => {
        this.studentEducationsByCostBearer = response.data;
        this.studentEducationsByCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.studentEducationsByCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.studentEducationsByCostBearerLoadingQueue--;
      });
  }

  // cost bearer unpaid invoices
  private costBearerUnpaidInvoicesRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public costBearerUnpaidInvoicesSuccess = false;
  public costBearerUnpaidInvoices = [];
  public costBearerUnpaidInvoicesError: Record<any, any> | null = null;
  private costBearerUnpaidInvoicesLoadingQueue = 0;

  public get costBearerUnpaidInvoicesLoading() {
    return this.costBearerUnpaidInvoicesLoadingQueue > 0;
  }

  public async costBearerUnpaidInvoicesAction(costBearerId: number): Promise<void> {
    this.costBearerUnpaidInvoicesLoadingQueue++;
    this.costBearerUnpaidInvoicesSuccess = false;
    this.costBearerUnpaidInvoicesError = null;

    return await this.costBearerUnpaidInvoicesRequest
      .makeRequest({
        method: "get",
        url: `/invoices/cost-bearer-unpaid/${costBearerId}`,
      })
      .then((response: AxiosResponse) => {
        this.costBearerUnpaidInvoices = response.data;
        this.costBearerUnpaidInvoicesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerUnpaidInvoicesError = error.response?.data;
      })
      .finally(() => {
        this.costBearerUnpaidInvoicesLoadingQueue--;
      });
  }

  // cost bearer appointments
  private costBearerAppointmentsRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public costBearerAppointmentsSuccess = false;
  public costBearerAppointments = [];
  public costBearerAppointmentsError: Record<any, any> | null = null;
  private costBearerAppointmentsLoadingQueue = 0;

  public get costBearerAppointmentsLoading() {
    return this.costBearerAppointmentsLoadingQueue > 0;
  }

  public async costBearerAppointmentsAction(costBearerId: number): Promise<void> {
    this.costBearerAppointmentsLoadingQueue++;
    this.costBearerAppointmentsSuccess = false;
    this.costBearerAppointmentsError = null;

    return await this.costBearerAppointmentsRequest
      .makeRequest({
        method: "get",
        url: `/appointments/cost-bearer/${costBearerId}`,
      })
      .then((response: AxiosResponse) => {
        this.costBearerAppointments = response.data;
        this.costBearerAppointmentsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerAppointmentsError = error.response?.data;
      })
      .finally(() => {
        this.costBearerAppointmentsLoadingQueue--;
      });
  }

  // cost bearer balances
  private costBearerBalancesRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public costBearerBalancesSuccess = false;
  public costBearerBalances = [];
  public costBearerBalancesError: Record<any, any> | null = null;
  private costBearerBalancesLoadingQueue = 0;

  public get costBearerBalancesLoading() {
    return this.costBearerBalancesLoadingQueue > 0;
  }

  public async costBearerBalancesAction(costBearerId: number): Promise<void> {
    this.costBearerBalancesLoadingQueue++;
    this.costBearerBalancesSuccess = false;
    this.costBearerBalancesError = null;

    return await this.costBearerBalancesRequest
      .makeRequest({
        method: "get",
        url: `/cost-bearers/balance/${costBearerId}`,
      })
      .then((response: AxiosResponse) => {
        this.costBearerBalances = response.data;
        this.costBearerBalancesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.costBearerBalancesError = error.response?.data;
      })
      .finally(() => {
        this.costBearerBalancesLoadingQueue--;
      });
  }

  // create cost bearer
  private createCostBearerPaymentRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public createCostBearerPaymentSuccess = false;
  public createCostBearerPaymentError: Record<any, any> | null = null;
  private createCostBearerPaymentLoadingQueue = 0;

  public get createCostBearerPaymentLoading() {
    return this.createCostBearerPaymentLoadingQueue > 0;
  }

  public async createCostBearerPaymentAction(data: any): Promise<void> {
    this.createCostBearerPaymentLoadingQueue++;
    this.createCostBearerPaymentSuccess = false;
    this.createCostBearerPaymentError = null;

    return await this.createCostBearerPaymentRequest
      .makeRequest({
        method: "post",
        url: `/cost-bearer-payments`,
        data,
      })
      .then((response: AxiosResponse) => {
        this.createCostBearerPaymentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createCostBearerPaymentError = error.response?.data;
      })
      .finally(() => {
        this.createCostBearerPaymentLoadingQueue--;
      });
  }

  // todo: move separate file
  // invoice switch paid
  private invoiceSwitchPaidRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public invoiceSwitchPaidSuccess = false;
  public invoiceSwitchPaidError: Record<any, any> | null = null;
  private invoiceSwitchPaidLoadingQueue = 0;

  public get invoiceSwitchPaidLoading() {
    return this.invoiceSwitchPaidLoadingQueue > 0;
  }

  public async invoiceSwitchPaidAction(invoiceId: number): Promise<void> {
    this.invoiceSwitchPaidLoadingQueue++;
    this.invoiceSwitchPaidSuccess = false;
    this.invoiceSwitchPaidError = null;

    return await this.invoiceSwitchPaidRequest
      .makeRequest({
        method: "put",
        url: `/invoices/switch-paid/${invoiceId}`,
      })
      .then((response: AxiosResponse) => {
        this.invoiceSwitchPaidSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.invoiceSwitchPaidError = error.response?.data;
      })
      .finally(() => {
        this.invoiceSwitchPaidLoadingQueue--;
      });
  }

  private archiveCostBearerRequest = createCancelableRequest(CostBearerRequestMixin.name);

  public archiveCostBearerSuccess = false;
  public archiveCostBearerError: Record<any, any> | null = null;
  private archiveCostBearerLoadingQueue = 0;

  public get archiveCostBearerLoading() {
    return this.archiveCostBearerLoadingQueue > 0;
  }

  public async archiveCostBearerAction(costBearerId: number, archived: boolean): Promise<void> {
    this.archiveCostBearerLoadingQueue++;
    this.archiveCostBearerSuccess = false;
    this.archiveCostBearerError = null;

    return await this.archiveCostBearerRequest
      .makeRequest({
        method: "post",
        url: `/cost-bearers/archive?id=${costBearerId}&archived=${archived}`,
      })
      .then((response: AxiosResponse) => {
        this.archiveCostBearerSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.archiveCostBearerError = error.response?.data;
      })
      .finally(() => {
        this.archiveCostBearerLoadingQueue--;
      });
  }
}
