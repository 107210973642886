









import { Component, Prop, Vue } from "vue-property-decorator";
import SignatureCapture from "@/views/Scheduler/SignatureCapture.vue";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { FscModal, SignatureCapture },
})
export default class SchedulerSignatureModal extends Vue {
  public name = "SchedulerSignatureModal";

  @Prop()
  public selectedLesson!: any;

  @Prop()
  public students!: any;

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop({ type: String, required: true })
  public eventName!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: any;
}
