import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";

const crudService = new CrudService();
const URL = "/driving-school-appointments";

@Component
export default class DrivingSchoolAppointmentMixin extends Vue {
  protected drivingSchoolAppointment: any | null = null;
  protected drivingSchoolAppointmentSuccess = false;
  protected drivingSchoolAppointmentError: Record<any, any> | null = null;
  protected drivingSchoolAppointmentLoading = false;

  public async fetchDrivingSchoolAppointment(appointmentId: number): Promise<void> {
    this.drivingSchoolAppointmentLoading = true;
    this.drivingSchoolAppointmentSuccess = false;
    this.drivingSchoolAppointmentError = null;
    return await crudService
      .findOne({
        resource: `${URL}`,
        id: appointmentId,
      })
      .then((response: AxiosResponse) => {
        this.drivingSchoolAppointment = response.data;
      })
      .catch((error: AxiosError) => {
        this.drivingSchoolAppointmentError = error.response?.data;
      })
      .finally(() => {
        this.drivingSchoolAppointmentLoading = false;
      });
  }
}
