















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AuthImage from "@/directives/AuthImage";
import axios from "@/utils/axios";
import { remove } from "@/utils/localForageUtil";
import debounce from "lodash/debounce";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";

@Component({
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class ProfilePhoto extends Vue {
  public name = "ProfilePhoto";

  protected profileSrc = "";

  public debounce: any;

  public mounted() {
    this.debounce = debounce((callback: any) => callback(), 100);
  }

  @Prop()
  public url!: any;

  @Prop({ type: String, default: () => "camera" })
  public placeholderIcon!: string;

  @Prop({ type: String, default: () => "64" })
  public height!: string;

  @Prop({ type: String, default: () => "64" })
  public width!: string;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;

  protected onFileChange(): void {
    const file = (this.$refs.file as HTMLFormElement).files[0];
    const fileSizeInvalid = !checkFileSizeBeforeUpload(file, UploadConfiguration.SIZE_NORMAL);
    if (fileSizeInvalid) {
      this.$toasted.error(String(this.$t("configuration.file_size_exceed_message", { size: " 2MB" })));
      return;
    }
    this.profileSrc = URL.createObjectURL(file);
    this.$emit("file", file);
  }

  public onUpload(): void {
    if (this.disabled) return;
    (this.$refs.file as HTMLFormElement).click();
    this.debounce(() => {
      remove(this.url);
    });
  }

  @Watch("url", { immediate: true })
  protected initUrl(): void {
    // this.fetchAvatar();
  }

  public fetchAvatar(): void {
    if (!this.url) return;

    axios({
      method: "get",
      url: this.url,
      responseType: "arraybuffer",
    })
      .then((response: any) => {
        const mimeType = response.headers["content-type"].toLowerCase();

        // @ts-ignore
        const imgBase64 = new Buffer(response.data, "binary").toString("base64");
        this.profileSrc = "data:" + mimeType + ";base64," + imgBase64;
      })
      .catch((error: any) => {
        this.profileSrc = "";
      });
  }

  public successHandler(src: string) {
    this.profileSrc = src;
  }
}
