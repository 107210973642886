












import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import MailButton from "@/components/Button/MailButton.vue";

const EmailsModule = namespace("emails");

@Component({
  components: { MailButton, FscSimpleCard, PrintButton },
})
export default class CostBearersPreviewPdfDocument extends Vue {
  public name = "CostBearersPreviewPdfDocument";

  @EmailsModule.Action("setBlobFile")
  private setBlobFile: any;

  @Prop()
  public blob!: any;

  @Prop()
  public loading!: any;

  public onPdfDocumentPrint(): void {
    console.log("on Document print");
  }

  public onPdfDocumentEmail(): void {
    const options = {
      blob: this.blob.blob ? this.blob.blob : null,
      mimeType: this.blob.mimeType ? this.blob.mimeType : null,
    };
    this.setBlobFile(options);
    this.$emit("on-document-email");
  }
}
