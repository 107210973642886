var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fsc-simple-card',{staticClass:"h-100"},[_c('template',{slot:"header"},[_c('h5',{staticClass:"my-10 text-fuscous-gray-500 font-size-16 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("todo.new_todo"))+" ")])]),_c('b-overlay',{attrs:{"show":_vm.todoIsLoading}},[_c('b-form',{staticClass:"row",on:{"submit":_vm.onSubmitTodo}},[_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.due_date"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.selectedDueDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('Datepicker',{attrs:{"input-class":{ 'is-invalid': invalid },"label-no-date-selected":_vm.$t('calendar.label_no_date'),"label-help":_vm.$t('calendar.label_help')},model:{value:(_vm.selectedDueDate),callback:function ($$v) {_vm.selectedDueDate=$$v},expression:"selectedDueDate"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.status"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.selectedStatus},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"loading":_vm.todoLoading,"options":_vm.statuses,"label":"name","appendToBody":true,"clearable":false},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(_vm._s(_vm.$t("general.title"))+" *")]),_c('validation',{attrs:{"validations":_vm.$v.selectedSubject},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSubject),expression:"selectedSubject"}],staticClass:"form-control",class:{ 'is-invalid': invalid },attrs:{"type":"text"},domProps:{"value":(_vm.selectedSubject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedSubject=$event.target.value}}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("roles.description"))+" ")]),_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.selectedTask),callback:function ($$v) {_vm.selectedTask=$$v},expression:"selectedTask"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.author"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.selectedAuthor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.instructors,"getOptionLabel":_vm.instructorCustomLabel,"label":"name","appendToBody":true,"clearable":false},model:{value:(_vm.selectedAuthor),callback:function ($$v) {_vm.selectedAuthor=$$v},expression:"selectedAuthor"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"6"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("sidebar.instructors"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.selectedUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"options":_vm.instructors,"getOptionLabel":_vm.instructorCustomLabel,"label":"name","appendToBody":true,"clearable":false},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"12"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.cost_bearer"))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.costBearersOptions,"label":"name","appendToBody":true,"clearable":false},model:{value:(_vm.selectedCostBearer),callback:function ($$v) {_vm.selectedCostBearer=$$v},expression:"selectedCostBearer"}})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"10"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.priority"))+" * ")]),_c('validation',{attrs:{"validations":_vm.$v.selectedPriority},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return _c('fsc-multiselect',{class:{ multiselect__invalid: invalid },attrs:{"loading":_vm.todoLoading,"options":_vm.priorities,"label":"name","appendToBody":true,"clearable":false},model:{value:(_vm.selectedPriority),callback:function ($$v) {_vm.selectedPriority=$$v},expression:"selectedPriority"}})}}])})],1),_c('b-col',{staticClass:"form-group",attrs:{"sm":"2"}},[_c('label',{staticClass:"font-size-14 text-tuatara-500"},[_vm._v(" "+_vm._s(_vm.$t("general.group"))+" ")]),_c('fsc-multiselect',{attrs:{"options":_vm.groups,"label":"color","appendToBody":true,"clearable":false,"searchable":false,"loading":_vm.todoLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(props){return _c('span',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"h-16 w-16 rounded-circle",class:{
                'border border-oxford-blue-400': _vm.isWhite(props.color),
              },style:({ backgroundColor: props.color })})])}},{key:"option",fn:function(props){return _c('span',{staticClass:"d-flex"},[_c('span',{staticClass:"h-16 w-16 rounded-circle",class:{
                'border border-oxford-blue-400': _vm.isWhite(props.color),
              },style:({ backgroundColor: props.color })})])}}]),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1),_c('b-col',{staticClass:"form-group pt-3",attrs:{"sm":"6"}},[_c('label',{staticClass:"custom-file-upload",attrs:{"for":"file-upload"}},[(!_vm.selectedAttachmentName)?_c('span',{staticClass:"font-weight-bold pr-20 mr-70"},[_vm._v(" "+_vm._s(_vm.$t("general.select_file"))+" ")]):_vm._e(),(_vm.selectedAttachmentName)?_c('span',{staticClass:"font-weight-bold pr-20 mr-70"},[_vm._v(" "+_vm._s(this.selectedAttachmentName)+" ")]):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":['fat', 'paperclip']}})],1),_c('input',{ref:"attachment",attrs:{"id":"file-upload","type":"file"},on:{"input":_vm.attachmentSelected}})]),(_vm.fileSizeInvalid)?_c('b-col',{staticClass:"col-md-12"},[_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$t("configuration.file_size_exceed_message", { size: "2MB" })))])]):_vm._e(),_c('b-col',{staticClass:"col-md-12"},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("configuration.recommended_files_upload_size", { mb: "2MB" })))])])],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btn-container"},[_c('abort-button',{staticClass:"fsc-btn-border",attrs:{"label":""},on:{"click":function($event){return _vm.onAbortTodo()}}}),_c('save-button',{staticClass:"ml-2 fsc-btn-border",attrs:{"label":_vm.$t('calendar.save_button').toString(),"loading":_vm.todoIsLoading},on:{"click":_vm.onSubmitTodo}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }