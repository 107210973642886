



























import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FilterButton from "@/components/Button/FilterButton.vue";

@Component({
  components: { FilterButton, AbortButton, FscModal },
})
export default class CostBearersFilterModal extends Vue {
  public name = "CostBearersFilterModal";

  @Prop({ type: String })
  public modalId!: string;

  @Prop({ default: () => false })
  protected archive!: boolean | null;

  public onClearFilter(cancel: () => void): void {
    this.$emit("update:archive", false);
    this.$emit("on-clear-filter", {
      archive: this.archive,
    });
    this.$emit("filterActive", false);
    cancel();
  }

  public onFilter(ok: () => void): void {
    this.$emit("on-filter", {
      archive: this.archive,
    });
    this.$emit("filterActive", true);
    ok();
  }

  protected get archiveOptions(): Array<any> {
    return [
      {
        label: this.$t("todo.all"),
        value: null,
      },
      {
        label: this.$t("general.only_active"),
        value: false,
      },
      {
        label: this.$t("general.only_archived"),
        value: true,
      },
    ];
  }

  protected onInput(value: boolean | Array<any>) {
    if (Array.isArray(value)) {
      this.$emit("update:archive", null);
    } else {
      this.$emit("update:archive", value);
    }
  }
}
