




import { Component, Prop, Vue } from "vue-property-decorator";
import { mask } from "vue-the-mask";

@Component({
  directives: { mask },
})
export default class FscInputNumber extends Vue {
  public name = "FscInputNumber";

  @Prop()
  public value!: any;

  @Prop({ default: () => 0 })
  public min!: any;

  @Prop({ default: () => 999999 })
  public max!: any;

  @Prop({ default: () => false })
  public disabled!: any;

  public get mask(): any {
    let mask = "";
    const symbol = "#";
    for (let i = 0; i < this.max.length; i++) {
      mask = `${mask}${symbol}`;
    }
    return mask;
  }

  public changeHandle(event: any): void {
    this.$emit("input", event.target.value);
  }
}
