


















































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import eventBus from "@/plugins/EventBus";
import _ from "lodash";

@Component({
  components: {
    Validation,
    FscInputDecimal,
    FscMultiselect,
    CreateButton,
    ActionButton,
  },
})
export default class CostBearerPaymentsByStudentEducation extends Vue {
  public name = "CostBearerPaymentsByStudentEducation";

  @Prop({ default: () => [] })
  private customersWithEducations!: any;

  @Prop({ default: () => [] })
  private unpaidInvoices!: any;

  @Prop({ default: 0 })
  private amount!: any;

  public controlSum = 0;
  public customerOptionSelected: any = 0;
  public unpaidInvoicesOptionSelected: any = 0;

  private selectedCustomers: any = [];
  private selectedUnpaidInvoices: any = [];

  public currentCustomer: any = {
    selectedItem: { id: 0, name: "", amount: 0, invoices: [], selectedInvoice: { amountInEur: 0 } },
  };
  public selectedCustomerInvoices: Array<any> = [];

  public currentUnpaidInvoice = {
    selectedItem: { id: 0, name: "", amountInEur: 0 },
  };

  public customers: any = [];
  public customerPayments: any = {};
  public clonedCustomers: Array<any> = [];

  public unpaidInvoicesOptions: any = [];
  public clonedUnpaidInvoicesOptions: Array<any> = [];
  public unpaidInvoicesPayments: any = {};

  public mounted() {
    this.clonedCustomers = _.cloneDeep(this.customersWithEducations);
    this.clonedUnpaidInvoicesOptions = _.cloneDeep(this.unpaidInvoices);
    this.customers = this.customersWithEducations;
    this.controlSum = this.amount;
  }

  public get filteredCustomers() {
    return this.customers;
  }

  public unpaidInvoiceOptionsMap(unpaidInvoices: any) {
    this.unpaidInvoicesOptions = unpaidInvoices.map((uni: any) => {
      return {
        id: uni.id,
        amountInEur: uni.amountInEur,
        name: uni.invoiceType ? `${uni.date} | ${uni.fullInvoiceNumber} | ${uni.amountInEur} €` : "",
      };
    });
  }

  public updated() {
    this.customerPayments = {};
    this.unpaidInvoicesPayments = {};

    if (this.currentCustomer.selectedItem.id !== 0) {
      if (this.currentCustomer.selectedItem.selectedInvoice.id) {
        this.unpaidInvoicesPayments[this.currentCustomer.selectedItem.selectedInvoice.id] = this.currentCustomer.selectedItem.amount;
      } else {
        this.customerPayments[this.currentCustomer.selectedItem.id] = this.currentCustomer.selectedItem.amount;
      }
    }

    this.selectedCustomers.forEach((customer: any) => {
      if (customer.selectedItem.id !== 0 && customer.selectedItem.amount > 0) {
        if (customer.selectedItem.selectedInvoice.id) {
          this.unpaidInvoicesPayments[customer.selectedItem.selectedInvoice.id] = customer.selectedItem.amount;
        } else {
          this.customerPayments[customer.selectedItem.id] = customer.selectedItem.amount;
        }
      }
    });

    if (this.currentUnpaidInvoice.selectedItem.id !== 0) {
      const costBearerInvoicePayment = {
        costBearerInvoiceId: this.currentUnpaidInvoice.selectedItem.id,
        amount: this.currentUnpaidInvoice.selectedItem.amountInEur,
      };
      // this.unpaidInvoicesPayments.push(costBearerInvoicePayment);
      this.unpaidInvoicesPayments[this.currentUnpaidInvoice.selectedItem.id] = this.currentUnpaidInvoice.selectedItem.amountInEur;
    }

    this.selectedUnpaidInvoices.forEach((invoice: any) => {
      if (invoice.selectedItem.id !== 0 && invoice.selectedItem.amountInEur > 0) {
        const costBearerInvoicePayment = {
          costBearerInvoiceId: invoice.selectedItem.id,
          amount: invoice.selectedItem.amountInEur,
        };
        // this.unpaidInvoicesPayments.push(costBearerInvoicePayment);
        this.unpaidInvoicesPayments[invoice.selectedItem.id] = invoice.selectedItem.amountInEur;
      }
    });
    if (Object.keys(this.customerPayments).length > 0 || Object.keys(this.unpaidInvoicesPayments).length > 0) {
      eventBus.$emit("customer-payments-updated", {
        studentsPayment: this.customerPayments,
        invoicesPayment: this.unpaidInvoicesPayments,
        controlSum: this.controlSum,
      });
    }
    this.calculateControlSum();
  }

  public validateCustomerAmount(input: any) {
    if (this.controlSum !== 0 && this.selectedCustomers.length > 0) {
      this.controlSum = parseFloat((this.controlSum + this.customerOptionSelected.amount - input).toFixed(2));
      this.customerOptionSelected.amount = input;
    } else if (this.controlSum !== 0 && this.selectedCustomers.length === 0) {
      this.controlSum = parseFloat((this.controlSum + this.customerOptionSelected.amount - input).toFixed(2));
      this.customerOptionSelected.amount = input;
    } else {
      this.controlSum = parseFloat((this.amount - input).toFixed(2));
    }
  }

  public validateUnpaidInvoiceAmount(input: any) {
    if (this.controlSum !== 0 && this.selectedUnpaidInvoices.length > 0) {
      this.controlSum = parseFloat((this.controlSum + this.unpaidInvoicesOptionSelected - input).toFixed(2));
      this.unpaidInvoicesOptionSelected = input;
    } else if (this.controlSum !== 0 && this.selectedUnpaidInvoices.length === 0) {
      this.controlSum = parseFloat((this.controlSum + this.unpaidInvoicesOptionSelected - input).toFixed(2));
      this.unpaidInvoicesOptionSelected = input;
    } else {
      this.controlSum = parseFloat((this.amount - input).toFixed(2));
    }
  }

  public calculateControlSum() {
    const selectedSum = this.selectedCustomers.reduce((acc: any, item: any) => acc + (item.selectedItem.amount ? item.selectedItem.amount : 0), 0);
    const currentClientSum = this.currentCustomer.selectedItem.amount || 0;
    const selectedUnpaidInvoices = this.selectedUnpaidInvoices.reduce(
      (acc: any, item: any) => acc + (item.selectedItem.amountInEur ? item.selectedItem.amountInEur : 0),
      0
    );
    const currentUnpaidInvoice = this.currentUnpaidInvoice.selectedItem.amountInEur;

    this.controlSum = parseFloat((this.amount - (selectedSum + currentClientSum + selectedUnpaidInvoices + currentUnpaidInvoice)).toFixed(2));
  }

  public addNewFormItems() {
    const invoiceId = this.currentCustomer.selectedItem.selectedInvoice?.id || 0;
    if (this.currentCustomer.selectedItem.invoices.length === 0 || !this.currentCustomer.selectedItem.selectedInvoice.id) {
      this.customers = this.filteredCustomers.filter((customer: any) => customer.id !== this.currentCustomer.selectedItem.id);
    } else {
      this.currentCustomer.selectedItem.invoices = this.currentCustomer.selectedItem.invoices.filter((invoice: any) => invoice.id !== invoiceId);
      if (this.currentCustomer.selectedItem.invoices.length === 0) {
        this.customers = this.filteredCustomers.filter((customer: any) => customer.id !== this.currentCustomer.selectedItem.id);
      }
    }

    if (!this.currentCustomer.selectedItem.selectedInvoice.id) {
      const selection: any = this.selectedCustomers.filter((c: any) => c.selectedItem.id === this.currentCustomer.selectedItem.id);
      const selectedSum = selection.reduce(
        (acc: any, item: any) => acc + (item.selectedItem.selectedInvoice.id ? item.selectedItem.selectedInvoice.amountInEur : 0),
        0
      );

      this.selectedCustomers = this.selectedCustomers.filter((customer: any) => +customer.selectedItem.id !== +this.currentCustomer.selectedItem.id);
      this.controlSum = parseFloat((this.amount - Number(selectedSum)).toFixed(2));
    }

    const clonedCustomer = _.cloneDeep(this.currentCustomer);
    const customer: any = Object.assign({}, clonedCustomer);
    this.selectedCustomers.push(customer);

    this.currentCustomer.selectedItem = {
      id: 0,
      name: "",
      amount: 0,
      invoices: [],
      selectedInvoice: { amountInEur: 0 },
    };
  }

  public addNewInvoices() {
    const invoiceOptions = this.unpaidInvoicesOptions.filter((invoice: any) => invoice.id !== this.currentUnpaidInvoice.selectedItem.id);
    this.unpaidInvoicesOptions = invoiceOptions;
    const unpaidInvoice: any = Object.assign({}, this.currentUnpaidInvoice);
    this.selectedUnpaidInvoices.push(unpaidInvoice);
    this.currentUnpaidInvoice.selectedItem = { id: 0, name: "", amountInEur: 0 };
  }

  public resetInputFields() {
    this.selectedCustomers = [];
    this.currentCustomer = {
      selectedItem: { id: 0, name: "", amount: 0, invoices: [], selectedInvoice: { amountInEur: 0 } },
    };
    this.selectedUnpaidInvoices = [];
    this.currentUnpaidInvoice = {
      selectedItem: { id: 0, name: "", amountInEur: 0 },
    };
    this.customers = this.clonedCustomers;
    this.unpaidInvoiceOptionsMap(this.clonedUnpaidInvoicesOptions);
    this.controlSum = this.amount;
  }

  @Watch("selectedCustomers", { deep: true, immediate: true })
  public onUpdate(val: any) {
    this.selectedCustomers = val;
  }

  @Watch("filteredOptions")
  public onChange(newVal: any) {
    return newVal;
  }

  @Watch("amount", { deep: true, immediate: true })
  public onAmountChange(amount: any) {
    this.controlSum = Number(amount);
    let totalCustomer = 0;
    let totalCurrentCustomer = 0;
    let unpaidTotal = 0;
    let unpaidCurrentTotal = 0;

    if (this.selectedCustomers?.length > 0) {
      totalCustomer = +this.selectedCustomers.reduce((acc: any, item: any) => acc + Number(item.selectedItem.amount), 0);
    }
    if (this.currentCustomer.selectedItem.amount) {
      totalCurrentCustomer = Number(this.currentCustomer.selectedItem.amount);
    }

    if (this.selectedUnpaidInvoices.length > 0) {
      unpaidTotal = +this.selectedUnpaidInvoices.reduce((acc: any, item: any) => acc + Number(item.selectedItem.amountInEur), 0);
    }
    if (this.currentUnpaidInvoice.selectedItem.amountInEur) {
      unpaidCurrentTotal = Number(this.currentUnpaidInvoice.selectedItem.amountInEur);
    }
    this.controlSum = parseFloat((Number(amount) - (totalCustomer + totalCurrentCustomer + unpaidTotal + unpaidCurrentTotal)).toFixed(2));
  }

  @Watch("unpaidInvoices", { deep: true, immediate: true })
  public onUnpaidInvoices(unpaidInvoices: any) {
    if (unpaidInvoices && unpaidInvoices.length > 0) {
      this.unpaidInvoiceOptionsMap(unpaidInvoices);
    }
  }

  public currentCustomerOptionChange(option: any) {
    // delete option.amount;
    this.currentCustomer.selectedItem.selectedInvoice = { amountInEur: 0 };
    this.currentCustomer.selectedItem.amount = this.currentCustomer.selectedItem.toPay;
    this.customerOptionSelected = {
      ...option,
      selectedInvoice: { amountInEur: 0 },
      amount: this.currentCustomer.selectedItem.toPay,
    };

    this.controlSum = parseFloat((this.amount - Number(this.currentCustomer.selectedItem.amount)).toFixed(2));
  }

  public currentCustomerInvoiceOptionChange(option: any) {
    this.currentCustomer.selectedItem.selectedInvoice = option;
    this.currentCustomer.selectedItem.amount = option.amountInEur;
    this.controlSum = parseFloat((this.controlSum - option.amountInEur).toFixed(2));
  }

  public currentUnpaidInvoiceOptionChange(option: any) {
    this.unpaidInvoicesOptionSelected = option.amountInEur;
    this.controlSum = parseFloat((this.controlSum - option.amountInEur).toFixed(2));
  }
}
