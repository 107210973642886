



































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import UserService from "@/services/UserService";
import { OTHER, THEORY_LESSON } from "@/constants/Appointments";
import { namespace } from "vuex-class";
import eventBus from "@/plugins/EventBus";

const TheoryLessonModule = namespace("theory-lesson");

@Component
export default class CustomEvent extends Vue {
  public name = "CustomEvent";

  @TheoryLessonModule.Getter("getBooked")
  protected getIsTheoryLessonBooked: any;

  @Prop()
  public event!: any;

  public get getTitle(): string {
    const { extendedProps } = this.event;

    let title = this.event.title;
    if (this.event.privateAppointment && extendedProps.instructorId !== UserService.getUser().id) {
      title = this.$t("calendar.private");
    }
    if (this.hasPayroll) {
      return "✔ " + title;
    }
    if (title === "null") {
      title = "";
    }

    if (this.isDayGridMonth && !this.isAllDay) return `${this.getTime} ${title}`;

    if (extendedProps.eventTypeId === THEORY_LESSON && !!extendedProps?.booked === true) {
      return "✔ " + title;
    }

    return title;
  }

  public get getLicenseClass(): string {
    return this.event.extendedProps.licenseClass;
  }

  public get getBrandAndModel(): string {
    return this.event.extendedProps.brandAndModel;
  }

  public get getTime(): string {
    return `${moment(this.event.startStr).format("HH:mm")} - ${moment(this.event.endStr).format("HH:mm")}`;
  }

  public get viewType(): string {
    return this.event._context.viewSpec.type;
  }

  public get isDayGridMonth(): boolean {
    return this.viewType == "dayGridMonth";
  }

  public get timeGridDay(): boolean {
    return this.viewType == "timeGridDay";
  }

  public get booked(): boolean {
    return this.event.booked;
  }

  public get hasPayroll(): boolean {
    const { extendedProps } = this.event;
    return extendedProps.eventTypeId === OTHER && extendedProps.includeInPayroll;
  }

  public get eventTypeId(): number {
    return this.event.extendedProps.eventTypeId;
  }

  public get isAllDay(): boolean {
    return this.event.allDay;
  }

  public get eventBackgroundColor(): string {
    return this.event.backgroundColor;
  }

  public get isCarEvent(): number {
    return this.event.extendedProps.carEvent;
  }

  public get getTitleOrBrandModel(): string {
    return this.isCarEvent ? this.getBrandAndModel : this.getTitle;
  }

  public get getInstructorNameOrBrandModel(): string {
    return !this.isCarEvent ? this.getBrandAndModel : "";
  }

  public get instructorInitials() {
    return this.event.extendedProps?.instructorNames?.join(" | ") || "";
  }
}
