















































































































































































































import { Component, Provide, ProvideReactive, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import Table from "@/components/Table.vue";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Actions from "@/components/Actions.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import ShoppingCartButton from "@/components/Button/ShoppingCartButton.vue";
import SaldoButton from "@/components/Button/SaldoButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import MailButton from "@/components/Button/MailButton.vue";
import { namespace } from "vuex-class";
import CostBearersInfo from "@/views/CostBearers/Info/CostBearersInfo.vue";
import CostBearersCreate from "@/views/CostBearers/CostBearersCreate.vue";
import CostBearerEdit from "@/views/CostBearers/CostBearerEdit.vue";
import ImgBox from "@/views/CostBearers/Info/ImgBox.vue";
import QrcodeButton from "@/components/Button/QrcodeButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import WalletButton from "@/components/Button/WalletButton.vue";
import CostBearersPreviewPdfDocument from "@/views/CostBearers/CostBearersPreviewPdfDocument.vue";
import CostBearersToDo from "@/views/CostBearers/CostBearersToDo.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import CostBearersFilterModal from "@/views/CostBearers/CostBearersFilterModal.vue";
import { downloadFile } from "@/utils/File";
import StudentDetails from "@/views/Student/StudentDetails.vue";
import StudentInfoMixin from "@/mixins/StudentInfoMixin";
import MandatePreviewDocument from "@/views/Student/MandatePreviewDocument.vue";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import FscModal from "@/components/Modal/FscModal.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import CostBearersEmailForm from "@/views/CostBearers/CostBearersEmailForm.vue";
import UserService from "@/services/UserService";
import ActionButton from "@/components/Button/ActionButton.vue";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import SaldoMixin from "@/mixins/SaldoMixin";
import CostBearerRequestMixin from "@/mixins/Request/CostBearerRequestMixin";
import { CostBearerPaymentsDocumentsMixin } from "@/mixins/Request/CostBearerPaymentsDocumentsMixin";
import CheckListRequestMixin from "@/mixins/Request/CheckListRequestMixin";
import CheckListTemplateRequestMixin from "@/mixins/Request/CheckListTemplateRequestMixin";
import CostBearerPreviewDocumentMixin from "@/mixins/CostBearerPreviewDocumentMixin";
import _ from "lodash";

const CostBearerModule = namespace("cost-bearer");
const CostBearerFilterModule = namespace("cost-bearer/localFilter");
const MainTabsModule = namespace("main-tabs");
const EmailsModule = namespace("emails");

@Component({
  components: {
    CustomSlider,
    ActionButton,
    CostBearersEmailForm,
    MandatePreviewDocument,
    StudentDetails,
    CostBearersFilterModal,
    FilterButton,
    CostBearersToDo,
    WalletButton,
    ImgBox,
    CostBearersCreate,
    Actions,
    Table,
    FscPageHeader,
    FscCardHeader,
    FscSimpleCard,
    CreateButton,
    EditButton,
    VerticalDivider,
    ShoppingCartButton,
    SaldoButton,
    PrintButton,
    FileCsvButton,
    MailButton,
    CostBearersInfo,
    CostBearerEdit,
    QrcodeButton,
    AbortButton,
    CostBearersPreviewPdfDocument,
    FscModal,
    SaveButton,
  },
})
export default class CostBearers extends mixins(
  ViewSwitchMixin,
  StudentInfoMixin,
  CostBearerMixin,
  StudentRequestMixin,
  SaldoMixin,
  CostBearerRequestMixin,
  CostBearerPaymentsDocumentsMixin,
  CheckListRequestMixin,
  CheckListTemplateRequestMixin,
  CostBearerPreviewDocumentMixin
) {
  public name = "CostBearers";

  private fields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
    {
      key: "balance",
      label: this.$t("students.balance"),
      sortable: false,
      thClass: "d-flex justify-content-end",
    },
  ];

  @CostBearerFilterModule.State("filter")
  public costBearerLocalFilter: any;

  @CostBearerFilterModule.Mutation("SET_LOCAL_FILTER")
  public setCostBearerLocalFilter!: (payload: Record<any, any>) => void;

  @CostBearerModule.Action("setSelectedRowName")
  public setSelectedRowName!: (name: string) => void;

  @MainTabsModule.Action("setCustomLabel")
  private setCustomLabelAction!: (label: string | null) => void;

  // email
  @EmailsModule.Getter("getSuccess")
  public emailSuccess: any;

  @EmailsModule.Getter("getError")
  public emailError: any;

  @Provide("cancelPaymentDocumentAction")
  private async cancelPaymentDocumentActionFn(invoiceForCancellationId: number) {
    return await this.cancelPaymentDocumentAction(invoiceForCancellationId);
  }

  @ProvideReactive("cancelPaymentDocumentLoading")
  private get cancelPaymentDocumentLoadingFn() {
    return this.cancelPaymentDocumentLoading;
  }

  @ProvideReactive("cancelPaymentDocumentSuccess")
  private get cancelPaymentDocumentSuccessFn() {
    return this.cancelPaymentDocumentSuccess;
  }

  // create cost bearer payment
  @Provide("createCostBearerPaymentAction")
  private async createCostBearerPaymentActionFn(data: any) {
    return await this.createCostBearerPaymentAction(data);
  }

  @ProvideReactive("createCostBearerPaymentLoading")
  private get createCostBearerPaymentLoadingFn() {
    return this.createCostBearerPaymentLoading;
  }

  @ProvideReactive("createCostBearerPaymentSuccess")
  private get createCostBearerPaymentSuccessFn() {
    return this.createCostBearerPaymentSuccess;
  }

  // invoice switch paid
  @Provide("invoiceSwitchPaidAction")
  private async invoiceSwitchPaidActionFn(invoiceId: number) {
    return await this.invoiceSwitchPaidAction(invoiceId);
  }

  @ProvideReactive("invoiceSwitchPaidLoading")
  private get invoiceSwitchPaidLoadingFn() {
    return this.invoiceSwitchPaidLoading;
  }

  @ProvideReactive("invoiceSwitchPaidSuccess")
  private get invoiceSwitchPaidSuccessFn() {
    return this.invoiceSwitchPaidSuccess;
  }

  @ProvideReactive("costBearerLoading")
  private get costBearerLoadingFn() {
    return this.costBearerLoading;
  }

  @ProvideReactive("documentIsLoading")
  private get documentIsLoadingFn() {
    return this.documentLoading;
  }

  //
  @ProvideReactive("studentEducationsByCostBearerLoading")
  private get studentEducationsByCostBearerLoadingFn() {
    return this.studentEducationsByCostBearerLoading;
  }

  @ProvideReactive("costBearerUnpaidInvoicesLoading")
  private get costBearerUnpaidInvoicesLoadingFn() {
    return this.costBearerUnpaidInvoicesLoading;
  }

  @ProvideReactive("costBearerAppointmentsLoading")
  private get costBearerAppointmentsLoadingFn() {
    return this.costBearerAppointmentsLoading;
  }

  @ProvideReactive("costBearerBalancesLoading")
  private get costBearerBalancesLoadingFn() {
    return this.costBearerBalancesLoading;
  }

  // archive cost bearer
  @Provide("archiveCostBearerAction")
  private async archiveCostBearerActionFn(costBearerId: number, archived: boolean) {
    return await this.archiveCostBearerAction(costBearerId, archived);
  }

  @ProvideReactive("archiveCostBearerLoading")
  private get archiveCostBearerLoadingFn() {
    return this.archiveCostBearerLoading;
  }

  @ProvideReactive("archiveCostBearerSuccess")
  private get archiveCostBearerSuccessFn() {
    return this.archiveCostBearerSuccess;
  }

  // checklist
  @Provide("checklistByCostBearerAction")
  private async checklistByCostBearerActionFn(costBearerId: number) {
    return await this.checklistByCostBearerAction(costBearerId);
  }

  @ProvideReactive("checklistByCostBearer")
  private get checklistByCostBearerFn() {
    return this.checklistByCostBearer;
  }

  @ProvideReactive("checklistByCostBearerLoading")
  private get checklistByCostBearerLoadingFn() {
    return this.checklistByCostBearerLoading;
  }

  // create checklist template
  @Provide("createChecklistTemplateByCostBearerAction")
  private async createChecklistTemplateByCostBearerActionFn(costBearerId: number, checklistTemplateId: number) {
    return await this.createChecklistTemplateByCostBearerAction(costBearerId, checklistTemplateId);
  }

  @ProvideReactive("createChecklistTemplateByCostBearerSuccess")
  private get createChecklistTemplateByCostBearerSuccessFn() {
    return this.createChecklistTemplateByCostBearerSuccess;
  }

  @ProvideReactive("createChecklistTemplateByCostBearerLoading")
  private get createChecklistTemplateByCostBearerLoadingFn() {
    return this.createChecklistTemplateByCostBearerLoading;
  }

  // delete checklist template
  @Provide("deleteChecklistTemplateByCostBearerAction")
  private async deleteChecklistTemplateByCostBearerActionFn(costBearerId: number, checklistTemplateId: number) {
    return await this.deleteChecklistTemplateByCostBearerAction(costBearerId, checklistTemplateId);
  }

  @ProvideReactive("deleteChecklistTemplateByCostBearerSuccess")
  private get deleteChecklistTemplateByCostBearerSuccessFn() {
    return this.deleteChecklistTemplateByCostBearerSuccess;
  }

  @ProvideReactive("deleteChecklistTemplateByCostBearerLoading")
  private get deleteChecklistTemplateByCostBearerLoadingFn() {
    return this.deleteChecklistTemplateByCostBearerLoading;
  }

  @Provide("deleteChecklistTemplateLocal")
  private deleteChecklistTemplateLocal(checklistTemplateId: number) {
    const index = this.checklistByCostBearer.findIndex((l) => l.checklistTemplateId === checklistTemplateId);
    if (index !== -1) this.checklistByCostBearer.splice(index, 1);
  }

  // check checklist by cost bearer
  @Provide("checkChecklistByCostBearerAction")
  private async checkChecklistByCostBearerActionFn(checklistItemId: number, checked: boolean) {
    return await this.checkChecklistByCostBearerAction(checklistItemId, checked);
  }

  @ProvideReactive("checkChecklistByCostBearerSuccess")
  private get checkChecklistByCostBearerSuccessFn() {
    return this.checkChecklistByCostBearerSuccess;
  }

  @ProvideReactive("checkChecklistByCostBearerLoading")
  private get checkChecklistByCostBearerLoadingFn() {
    return this.checkChecklistByCostBearerLoading;
  }

  //checklist template
  @Provide("findAllCheckListTemplates")
  private async findAllCheckListTemplatesFn() {
    return await this.findAllCheckListTemplates();
  }

  @ProvideReactive("checkListTemplates")
  private get checkListTemplatesFn() {
    return this.checkListTemplates;
  }

  @ProvideReactive("checkListTemplatesSuccess")
  private get checkListTemplatesSuccessFn() {
    return this.checkListTemplatesSuccess;
  }

  @ProvideReactive("checkListTemplatesLoading")
  private get checkListTemplatesLoadingFn() {
    return this.checkListTemplatesLoading;
  }

  public selectedCostBearersRow: any = null;

  public selectedEducationRow: any = null;

  public selectedPdfDocument: any = null;

  public visibleCostBearerMain = true;

  public visibleCostBearerInfo = false;

  public visibleCostBearerCreate = false;

  public visibleCostBearerUpdate = false;

  public visibleCostBearersStudentInfo = false;

  public visiblePdfDocument = false;

  public visibleToDo = false;

  private selectedRowIndex: any;

  public balanceActive = 0;

  public balanceBottom = 0;

  public balanceStudentActive = 0;
  public balanceStudentBottom = 0;

  protected archived = false;
  protected costBearerModalFilterId = "costBearerModalFilterId";
  protected filterActive = false;

  public query: Record<any, any> | null = null;

  public learnStatusActive = 0;
  protected visibleDocumentUpload = false;

  public selectedEmailType = null;
  public emailTypeOptions = [
    { text: this.$t("user.single_customer"), value: 1 },
    { text: this.$t("user.multiple_customers"), value: 2 },
    { text: this.$t("user.empty_email"), value: 3 },
  ];
  public visibleEmailForm = false;

  public user = this.currentUser || null;
  public fileSizeInvalid = false;
  public documentData = null;

  public onClickedEducationRow(education: any): void {
    this.selectedEducationRow = education;
    this.visibleCostBearerMain = false;
    this.visibleCostBearersStudentInfo = true;
    this.visiblePdfDocument = false;
    this.visibleEmailForm = false;
    this.resetBalance();

    if (education) {
      this.fetchStudent(education.studentId);
    }
  }

  public async onPdfDocumentClicked(item: any): Promise<void> {
    const result = await this.fetchDocument(item);
    if (result.success) {
      this.documentData = result.blob;
      this.selectedPdfDocument = item;
      this.visibleCostBearerMain = false;
      this.visiblePdfDocument = true;
      this.visibleCostBearersStudentInfo = false;
      this.visibleEmailForm = false;
    }
  }

  public balanceTabView(): any {
    return (this.balanceActive = 1), (this.balanceBottom = 4);
  }

  public balanceTabStudentView(): any {
    return (this.balanceStudentActive = 1), (this.balanceStudentBottom = 3);
  }

  public resetBottomTab(tab: any): void {
    this.balanceBottom = tab;
  }

  public resetStudentBottomTab(tab: any): void {
    this.balanceStudentBottom = tab;
  }

  public resetBalance(): any {
    this.balanceActive = 0;
  }

  public async resetCostBearers(): Promise<any> {
    this.balanceActive = 0;
    await this.fetchCostBearers();
  }

  public async costBearerCreateSubmit(data: any): Promise<void> {
    await this.createCostBearerAction(data.bearer);
    if (this.createCostBearerSuccess && this.createdCostBearerId) {
      await this.uploadFile(this.createdCostBearerId, data.file);
      await this.fetchCostBearers();
      this.closeCostBearersCreateForm();
    }
  }

  public async costBearerUpdateSubmit(data: any): Promise<void> {
    data.bearer.id = this.selectedCostBearersRow.id;
    await this.updateCostBearerAction(data.bearer.id, data.bearer);
    if (this.updateCostBearerSuccess) {
      await this.uploadFile(this.selectedCostBearersRow.id, data.file);
      await this.fetchCostBearers();
      this.closeCostBearersUpdateForm();
    }
  }

  private get selectedCostBearerId() {
    return this.selectedCostBearersRow?.id;
  }

  public onCostBearersRowClicked(bearer: any, indexInTable: any): void {
    if (this.selectedCostBearersRow?.id != bearer?.id) {
      this.$root.$emit("refresh-table-cost-bearers-attendees");
      this.selectedRowIndex = indexInTable;
      this.visibleCostBearerCreate = false;
      this.visibleCostBearerUpdate = false;
      this.visibleCostBearerInfo = true;
      this.selectedCostBearersRow = bearer;
      this.visibleEmailForm = false;
      this.balanceActive = 0;
      this.balanceBottom = 0;

      const costBearerId = bearer.id;

      // find cost bearer
      this.getCostBearerAction(costBearerId);

      // find student educations by cost bearer id
      this.studentEducationsByCostBearerAction(costBearerId);

      // find cost bearer unpaid invoices
      this.costBearerUnpaidInvoicesAction(costBearerId);

      //  find cost bearer appointments
      this.costBearerAppointmentsAction(costBearerId);

      // find cost bearer balances
      this.costBearerBalancesAction(costBearerId);
    }
  }

  public openCostBearerCreateForm(): void {
    this.visibleCostBearerInfo = false;
    this.visibleCostBearerUpdate = false;
    this.visibleCostBearerCreate = true;
    this.visibleEmailForm = false;
  }

  public closeCostBearersCreateForm(): void {
    if (this.selectedCostBearersRow !== null) {
      this.visibleCostBearerCreate = false;
      this.visibleCostBearerInfo = true;
    } else {
      this.visibleCostBearerCreate = false;
    }
  }

  public closeCostBearersUpdateForm(): void {
    if (this.selectedCostBearersRow !== null) {
      this.visibleCostBearerCreate = false;
      this.visibleCostBearerInfo = true;
      this.visibleCostBearerUpdate = false;
      this.visibleEmailForm = false;
    }
  }

  public openCostBearerEditForm(): void {
    if (this.selectedCostBearersRow !== null) {
      this.visibleCostBearerCreate = false;
      this.visibleCostBearerInfo = false;
      this.visibleCostBearerUpdate = true;
      this.visibleEmailForm = false;
    }
    return;
  }

  protected async uploadFile(costBearerId: number, file: File | null): Promise<void> {
    if (!file || !costBearerId) return;

    const formData = new FormData();
    formData.append("file", file);
    await this.uploadCostBearerFileAction(costBearerId, formData);
  }

  protected onQRCode(): void {
    this.$router.push({ name: "CostBearerUploadQrcodeDocument" });
  }

  protected async requestQuery(query: Record<any, any>): Promise<void> {
    this.$store.commit("cost-bearer/searchLocalFilter/SET_LOCAL_FILTER", query?.searchTerm || "");

    this.query = {
      ...query,
      calculateBalance: true,
      archived: this.archived,
    };
    return await this.filterCostBearersAction(this.query);
  }

  protected async fetchCostBearers(): Promise<void> {
    if (!this.query) return;
    await this.requestQuery(this.query);
  }

  private onClickCostBearerServices(): void {
    if (!this.selectedCostBearersRow) return;
    this.setCustomLabelAction(`${this.$t("students.services")} ${this.selectedCostBearersRow.name}`);
    this.setSelectedRowName(String(this.selectedCostBearersRow.name));
    this.$router.push({
      name: "CostBearerServices",
      params: {
        id: this.selectedCostBearersRow.id,
        costBearer: this.selectedCostBearersRow,
      },
    });
  }

  protected async onClickBilling(): Promise<void> {
    if (!this.selectedCostBearersRow) return;
    await this.$router.push({
      name: "CostBearerClassicPay",
      params: {
        id: this.selectedCostBearersRow.id,
      },
    });
  }

  public onCostBearersStudentInfoClose(): void {
    this.visibleCostBearerMain = true;
    this.visibleCostBearersStudentInfo = false;
  }

  public onCostBearersPreviewPdfDocumentClose(): void {
    this.visibleCostBearerMain = true;
    this.visiblePdfDocument = false;
  }

  public openCreateToDoForm(): void {
    this.visibleToDo = true;
    this.visibleCostBearerCreate = false;
    this.visibleCostBearerUpdate = false;
    this.visibleCostBearerInfo = false;
    this.visibleEmailForm = false;
    this.fileSizeInvalid = false;
  }

  public onAbortTodo(): void {
    this.visibleToDo = false;
  }

  public mounted() {
    if (this.costBearerLocalFilter instanceof Object) {
      this.archived = this.costBearerLocalFilter.archive;
      this.filterActive = true;
    }
  }

  private initalSearchTerm = null;

  public created() {
    this.initalSearchTerm = this.$store.getters["cost-bearer/searchLocalFilter/getFilter"];
  }

  protected onClickFilter(): void {
    this.$bvModal.show(this.costBearerModalFilterId);
  }

  protected onFilter(filter: { archive: boolean }): void {
    this.setCostBearerLocalFilter(filter);
    this.fetchCostBearers();
  }

  protected onFilterActive(value: boolean): void {
    this.filterActive = value;
  }

  @Provide("fetchCostBearers")
  public fetchCostBearersProvider() {
    this.fetchCostBearers();
  }

  protected onCsvExport(): void {
    const query: any = _.clone(this.query);
    delete query.offset;
    delete query.limit;

    downloadFile({
      method: "post",
      url: "cost-bearers/filter/csv",
      data: query,
    });
  }

  public changeActiveLearnStatusTab(index: any): void {
    this.learnStatusActive = index;
  }

  protected get studentId(): number | null {
    return this.selectedEducationRow ? this.selectedEducationRow.studentId : null;
  }

  public onEmailSelectionModalShow(): void {
    if (this.selectedCostBearersRow) {
      this.$bvModal.show("cost-bearer-email-modal");
    }
  }

  public onEmailCompose(): void {
    if (this.selectedEmailType) {
      this.visibleCostBearerCreate = false;
      this.visibleCostBearerUpdate = false;
      this.visibleCostBearerInfo = false;
      this.visibleEmailForm = true;
      this.$bvModal.hide("cost-bearer-email-modal");
    }
    return;
  }

  public onEmailAbort(): void {
    this.$bvModal.hide("cost-bearer-email-modal");
  }

  public onEmailAbortSend(): void {
    this.visibleEmailForm = false;
    this.selectedEmailType = null;
  }

  public get currentUser(): any {
    return UserService.getUser();
  }

  @Watch("emailSuccess")
  public onEmailSuccess(success: any) {
    if (success) {
      this.visibleEmailForm = false;
      this.visibleCostBearerInfo = true;
    }
  }

  @ProvideReactive("selectedStudent")
  private get selectedRowReactive(): any {
    return this.student;
  }

  public onDocumentEmail(): void {
    this.visibleCostBearerCreate = false;
    this.visibleCostBearerUpdate = false;
    this.visibleCostBearerInfo = false;
    this.visiblePdfDocument = false;
    this.visibleEmailForm = true;
    this.visibleCostBearerMain = true;
  }

  public get studentEducationIds() {
    return this.student?.studentEducationIds || [];
  }

  private get isLoading() {
    return this.filterCostBearersLoading || this.updateCostBearerLoading || this.createCostBearerLoading;
  }

  private get costBearerFormLoading() {
    return this.isLoading || this.costBearerLoading;
  }
}
