

























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import TimePicker from "@/components/TimePicker.vue";
import Validation from "@/components/Validation.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { APPOINTMENTS_TYPE } from "@/constants/Appointments";
import BookButton from "@/components/Button/BookButton.vue";
import { TranslateResult } from "vue-i18n";
import FscInputNumber from "@/components/Field/FscInputNumber.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import RecurringModal from "@/views/Scheduler/RecurringModal.vue";
import instructor from "@/router/Instructor";
import CopyButton from "@/components/Button/CopyButton.vue";
import ParticipantsTable from "@/views/Scheduler/ParticipantsTable.vue";
import TheoryMixin from "@/mixins/TheoryMixin";
import { formatInstructorName } from "@/utils/NameUtil";

const InstructorModule = namespace("instructor");
const LicenseClassModule = namespace("license-class");
const TheoryLessonModule = namespace("theory-lesson");

@Component({
  components: {
    RecurringModal,
    FscSimpleCard,
    FscInputNumber,
    BookButton,
    Datepicker,
    Validation,
    TimePicker,
    FscCard,
    SaveButton,
    AbortButton,
    FormCard,
    RemoveButton,
    CopyButton,
    ParticipantsTable,
  },
})
export default class TheoryLessonDetails extends mixins(TheoryMixin) {
  public name = "TheoryLessonDetails";

  public date: string | null = null;
  public time: string | null = null;
  public duration: number | undefined = 0;
  public appointmentType = APPOINTMENTS_TYPE[1];

  public note = null;

  public defaultDuration: number | undefined = 0;

  public activeTab = 0;

  @Prop()
  public theoryLesson!: any;

  @TheoryLessonModule.Action("book")
  protected theoryLessonBook: any;

  @TheoryLessonModule.Getter("getBooked")
  protected getTheoryLessonIsBooked: any;

  @TheoryLessonModule.Mutation("SET_BOOKED")
  protected setTheoryLessonIsBooked: any;

  public get endTime(): any {
    //TODO
    return "";
  }
  // protected get isTheoryLessonBooked(){
  //   console.log(this.theoryLesson.id)
  //   return this.theoryLesson?.booked;
  // }
  protected get isTheoryLessonActual() {
    if (!this.theoryLesson) return false;
    return this.hasTheoryLessonGetActual(this.theoryLesson);
  }
  public studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];

  public onAbort(): void {
    this.$emit("abort");
  }

  // public onBookTheoryLesson() {
  //   if (!this.theoryLesson.id) return;
  //   this.theoryLessonBook(this.theoryLesson.id);
  // }
  public get instructorName() {
    return formatInstructorName(this.theoryLesson.instructor.firstName, this.theoryLesson.instructor.lastName);
  }

  public get tabLabel(): any {
    return (index: any) => (index === 0 ? this.$t("calendar.theory_details_info_tab") : this.$t("calendar.theory_details_participants"));
  }

  public get isDisabled(): boolean {
    return true;
    // return this.hasNotWritePermission;
  }

  protected get getLabel(): TranslateResult {
    return this.$t("calendar.theory_lesson_title") || "";
  }

  // protected setDateTime(clickDate: string): void {
  //   // this.date = clickDate.toString().slice(0, 10);
  //   // this.time = clickDate.toString().slice(11, 16);
  // }

  public changeTab(index: number) {
    this.activeTab = index;
  }

  // protected get hasNotWritePermission(): boolean {
  //   return !this.$can(this.MODULE_TIME_PLANNER_WRITE);
  // }

  protected formatEducations(item: any): string {
    return item.activeEducations?.join(" | ") || "";
  }
}
