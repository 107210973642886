











import { Component, Prop, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class FullScreenButton extends Vue {
  public name = "FullScreenButton";

  @Prop({ default: () => false })
  public toggle!: boolean;
}
