












import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import AuthImage from "@/directives/AuthImage";
import instructorPlaceholder from "@/assets/teacherimgplaceholder.png";

@Component({
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class ImgBox extends Vue {
  public name = "ImgBox";

  @Prop({ required: true })
  public id!: any;

  get costBearerAvatarUrl(): string {
    const COST_BEARERS_URL = "cost-bearers";
    return `${COST_BEARERS_URL}/logo-image?costBearerId=${this.id}`;
  }

  public handleError(event: Event): void {
    (event.target as HTMLImageElement).src = instructorPlaceholder;
    (event.target as HTMLImageElement).alt = "Profile";
  }

  protected get hasId(): boolean {
    return Boolean(this.id);
  }
}
