












import { Component, Prop, Vue } from "vue-property-decorator";
import Table from "@/components/Table.vue";

@Component({
  components: { Table },
})
export default class InstructorsTable extends Vue {
  protected name = "ParticipantsTable";

  @Prop({ type: Array, default: () => [], required: true })
  public instructors!: any;

  public instructorFields = [
    {
      key: "firstName",
      label: this.$t("user.first_name"),
      sortable: false,
    },
    {
      key: "lastName",
      label: this.$t("user.last_name"),
      sortable: false,
    },
    {
      key: "initials",
      label: this.$t("general.initials"),
      sortable: false,
    },
  ];
}
