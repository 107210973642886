
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { mixins } from "vue-class-component";
import AppointmentRequestMixin from "@/mixins/Request/AppointmentRequestMixin";
import { PropType } from "vue";
import { IStudent } from "@/interfaces/Student/IStudent";

@Component({
  components: { FscSimpleCard, Table },
})
export default class StudentTimePlannerAppointments extends mixins(AppointmentRequestMixin) {
  public name = "StudentTimePlannerAppointments";

  @Prop({ type: Array })
  public appointments!: Array<any>;

  @Prop({ type: Boolean, default: () => false })
  public hideFilter!: boolean;

  @Prop({ type: String, default: () => "fsc-simple-card" })
  public tag!: string;

  @Prop({ type: Array, default: () => [] })
  public licenseClasses!: Array<{ label: string; value: string | null }>;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public licenseClassFilter = { label: this.$t("todo.all"), value: null };

  public fields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
    },
    {
      key: "name",
      label: this.$t("calendar.product"),
    },
    {
      key: "standardProductShortName",
      label: this.$t("product.assignment"),
    },
    {
      key: "instructorInitials",
      label: this.$t("general.driving_instructor"),
    },
    {
      key: "duration",
      label: this.$t("general.min"),
    },
  ];

  public get appointmentsFiltered(): Array<any> {
    if (this.licenseClassFilter.value) {
      return this.appointments.filter((x: any) => x.licenseClass === this.licenseClassFilter.value || x.licenseClass === null);
    }
    return this.appointments;
  }

  @Watch("licenseClasses", { deep: true })
  public onChangeLicenseClasses(): void {
    // Reset licenseClassFilter
    this.licenseClassFilter = { label: this.$t("todo.all"), value: null };
  }
}
