


























































































import { Component, InjectReactive, Prop } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Actions from "@/components/Actions.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import FileXmarkButton from "@/components/Button/FileXmarkButton.vue";
import { IPaymentDocument } from "@/interfaces/IPaymentDocument";
import { getFile } from "@/utils/File";
import _ from "lodash";
import CancelInvoiceModal from "@/views/AutomaticPaymentsOverview/CancelInvoiceModal.vue";
import { CANCELABLE_INVOICE } from "@/constants/PaymentDocumentType";
import CostBearersPaymentDocumentsFilter from "@/views/CostBearers/Info/CostBearersPaymentDocumentsFilter.vue";
import axios from "@/utils/axios";
import { AxiosResponse } from "axios";
import { mixins } from "vue-class-component";
import SaldoMixin from "@/mixins/SaldoMixin";
import { CostBearerPaymentsDocumentsMixin } from "@/mixins/Request/CostBearerPaymentsDocumentsMixin.ts";

@Component({
  components: {
    CancelInvoiceModal,
    FileXmarkButton,
    PrintButton,
    FileCsvButton,
    FilterAndSearch,
    Actions,
    Table,
    FscSimpleCard,
    CostBearersPaymentDocumentsFilter,
  },
})
export default class CostBearerPaymentDocuments extends mixins(CostBearerPaymentsDocumentsMixin, SaldoMixin) {
  public name = "CostBearerPaymentDocuments";

  @InjectReactive("costBearerLoading")
  public get costBearerPaymentsDocumentsLoading() {
    return this.costBearerPaymentsDocumentsLoadingQueue > 0;
  }

  @InjectReactive("documentIsLoading")
  public documentPdfIsLoading!: boolean;

  public get costBearerPaymentDocumentsFiltered() {
    return this.costBearerPaymentsDocuments;
  }

  public filter: any = null;

  private fields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "date",
      label: this.$t("calendar.form_date"),
      sortable: false,
    },
    {
      key: "paymentDocumentNumber",
      label: this.$t("general.number"),
      sortable: false,
    },
    {
      key: "paymentDocumentTypeName",
      label: this.$t("general.type"),
      sortable: false,
    },
    {
      key: "studentName",
      label: this.$t("general.customer"),
      sortable: false,
    },
    {
      key: "amountInEur",
      label: this.$t("students.amount"),
      sortable: false,
    },
    {
      key: "pdf",
      label: "",
      sortable: false,
    },
  ];
  protected filtered = "";
  protected selectedCostBearerPaymentDocuments: Array<IPaymentDocument> = [];
  protected cancelInvoiceModalId: any = "cost-bearer-cancel-invoice-modal";
  protected selectedRow: IPaymentDocument | null = null;

  @Prop()
  public selectedCostBearerId!: any;

  public filterActiveIcon = false;

  public query = {};
  public currentCostBearerDocumentId = 0;

  public mounted(): void {
    this.filter = { costBearerId: this.selectedCostBearerId };
  }

  public onFilter(query: any = null) {
    this.query = query ?? this.query;
    this.fetchCostBearerPaymentsDocuments({
      ...this.filter,
      ...this.query,
    });
  }

  protected onChangeFilter(): void {
    (this.$refs.costBearerPaymentDocuments as Table).onSearch(this.filtered);
  }

  protected onSelectedResources(costBearerPaymentDocuments: Array<IPaymentDocument>): void {
    this.selectedCostBearerPaymentDocuments = costBearerPaymentDocuments;
  }

  protected async onClickPdf(item: IPaymentDocument): Promise<void> {
    this.currentCostBearerDocumentId = item.costBearerDocumentId;
    await this.$emit("pdf-document-preview", item);
  }

  protected onClickDot(item: IPaymentDocument): void {
    this.$emit("dot-click", item);
  }

  public setCircleColor(item: any) {
    if (item.paid === null) {
      return `background-color: #808080`;
    } else if (item.paid === true) {
      return `background-color: #008000`;
    } else {
      return `background-color: #FF0000`;
    }
  }

  protected get documentIds(): any {
    let hasIds = false;
    const ids = _.reduce(
      this.selectedCostBearerPaymentDocuments,
      function (
        result: {
          studentDocumentIds: Array<any>;
          costBearerDocumentIds: Array<any>;
        },
        value: IPaymentDocument
      ) {
        if (value.studentDocumentId) {
          result.studentDocumentIds.push(value.studentDocumentId);
          hasIds = true;
        }
        if (value.costBearerDocumentId) {
          result.costBearerDocumentIds.push(value.costBearerDocumentId);
          hasIds = true;
        }
        return result;
      },
      {
        studentDocumentIds: [],
        costBearerDocumentIds: [],
      }
    );
    return hasIds ? ids : null;
  }

  protected async onClickPrint(): Promise<void> {
    if (!this.selectedCostBearerPaymentDocuments.length) {
      this.$toasted.info(this.$t("messages.select_documents").toString());
      return;
    }
    const documentIds = this.documentIds;
    if (!documentIds) return;
    await getFile({
      method: "post",
      url: `/payment-documents/print-preview`,
      data: documentIds,
    });
  }

  protected onCancelInvoice(): void {
    if (this.selectedCostBearerPaymentDocuments.length > 1) {
      this.$bvModal.show("moreThanOneInvoiceModal");
    } else if (!this.selectedIsCancelable || this.disabled) {
      return;
    } else {
      this.$bvModal.show(this.cancelInvoiceModalId);
    }
  }

  protected get selectedIsCancelable(): boolean {
    return Boolean(
      (this.selectedCostBearerPaymentDocuments[0]?.paymentDocumentId || this.selectedCostBearerPaymentDocuments[0]?.costBearerDocumentId) &&
        CANCELABLE_INVOICE.includes(this.selectedCostBearerPaymentDocuments[0]?.paymentDocumentType)
    );
  }

  protected onRowClicked(row: IPaymentDocument): void {
    this.selectedRow = row;
  }

  protected async okCancelInvoice(): Promise<void> {
    if (this.selectedCostBearerPaymentDocuments[0]?.costBearerDocumentId) {
      this.$emit("cancel-invoice-by-cost-bearer-document", this.selectedCostBearerPaymentDocuments[0].paymentDocumentId);
    } else if (this.selectedCostBearerPaymentDocuments[0]?.paymentDocumentId) {
      this.$emit("cancel-invoice-by-payment-document", this.selectedCostBearerPaymentDocuments[0].paymentDocumentId);
    }
  }

  protected async onCsvExport(): Promise<void> {
    if (this.filter) {
      return await axios
        .post(`/payment-documents/filter/csv`, this.filter, {
          responseType: "blob",
        })
        .then((response: AxiosResponse) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  }

  public onFilterModalPopup(): any {
    this.$bvModal.show("costBearerFilterPopover");
  }

  public async onPaymentDocumentsFilter(data: { filter: any }): Promise<void> {
    this.filter = data.filter;
    await this.fetchCostBearerPaymentsDocuments({
      ...this.filter,
      ...this.query,
      offset: 0,
    });
    this.filterActiveIcon = true;
    this.$bvModal.hide("costBearerFilterPopover");
  }

  public async onAbortPaymentDocumentsFilter(data: { filter: any }): Promise<void> {
    this.filter = data.filter;
    await this.fetchCostBearerPaymentsDocuments({
      ...this.filter,
      ...this.query,
      offset: 0,
    });
    this.filterActiveIcon = false;
  }

  @InjectReactive("isDisabled")
  public disabled!: boolean;
}
