








































































































import { Component, Inject, InjectReactive, Prop, Provide } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { mixins } from "vue-class-component";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: {
    AbortButton,
    SaveButton,
    FscModal,
  },
})
export default class CostBearersChecks extends mixins(CostBearerMixin) {
  public name = "CostBearersChecks";

  // checklist
  @Inject("checklistByCostBearerAction")
  private findCheckListsByCostBearerId!: (costBearerId: number) => Promise<void>;

  @InjectReactive("checklistByCostBearer")
  private allCheckListsByCostBearerId: any;

  @InjectReactive("checklistByCostBearerLoading")
  private loadingCheckList: any;

  @Inject("createChecklistTemplateByCostBearerAction")
  private createChecklistTemplateByCostBearerAction!: (costBearerId: number, checklistTemplateId: number) => Promise<void>;

  @InjectReactive("createChecklistTemplateByCostBearerSuccess")
  private createChecklistTemplateByCostBearerSuccess!: boolean;

  @InjectReactive("createChecklistTemplateByCostBearerLoading")
  private createChecklistTemplateByCostBearerLoading!: boolean;

  // check checklist by cost bearer
  @Inject("checkChecklistByCostBearerAction")
  private costBearerCheckListChecked!: (checklistItemId: number, checked: boolean) => Promise<void>;

  @InjectReactive("checkChecklistByCostBearerSuccess")
  private checkChecklistByCostBearerSuccess!: boolean;

  @InjectReactive("checkChecklistByCostBearerLoading")
  private checkChecklistByCostBearerLoading!: boolean;

  // checklist template
  @Inject("findAllCheckListTemplates")
  private findAllCheckListTemplates!: () => Promise<void>;

  @InjectReactive("checkListTemplates")
  private allCheckListTemplates: any;

  @InjectReactive("checkListTemplatesSuccess")
  private checkListTemplatesSuccess!: boolean;

  @InjectReactive("checkListTemplatesLoading")
  private checkListTemplatesLoading!: boolean;

  // remove checklist template
  @Inject("deleteChecklistTemplateByCostBearerAction")
  private deleteChecklistTemplateByCostBearerAction!: (costBearerId: number, checklistTemplateId: number) => Promise<void>;

  @InjectReactive("deleteChecklistTemplateByCostBearerSuccess")
  private deleteChecklistTemplateByCostBearerSuccess!: boolean;

  @InjectReactive("deleteChecklistTemplateByCostBearerLoading")
  private deleteChecklistTemplateByCostBearerLoading!: boolean;

  @Inject("deleteChecklistTemplateLocal")
  private deleteChecklistTemplateLocal!: (checklistTemplateId: number) => void;

  @Prop()
  public selectedCostBearerId: any;

  public activeCheckListTab = 0;
  public selectedCheckListTemplate: any = "";

  public async mounted(): Promise<void> {
    if (this.selectedCostBearerId) {
      await this.onReloadCheckListsAndCheckListTemplates(this.selectedCostBearerId);
    }
  }

  public get hasSelectedChecklistTemplate() {
    return this.selectedCheckListTemplate && this.selectedCheckListTemplate?.id;
  }

  public changeCheckListTemplate(index: number): void {
    this.activeCheckListTab = index;
  }

  public async deleteCostBearerCheckList(): Promise<void> {
    const templateId = this.localCheckLists[this.activeCheckListTab].checklistTemplateId;
    const costBearerId = this.selectedCostBearerId;
    await this.deleteChecklistTemplateByCostBearerAction(costBearerId, templateId);
    if (this.deleteChecklistTemplateByCostBearerSuccess) {
      this.deleteChecklistTemplateLocal(templateId);
    }
  }

  public onAbortCheckListTemplateDelete(): void {
    this.$bvModal.hide("deleteCheckListTemplatePopover");
  }

  public onAbortCheckListTemplateSelect(): void {
    this.selectedCheckListTemplate = "";
    this.$bvModal.hide("addCheckListTemplatePopover");
  }

  public onCheckBoxCheckListItemChange(item: any) {
    // Toggle on/off
    item.checked = !item.checked;
    this.costBearerCheckListChecked(item.id, item.checked);
  }

  public onCreateCostBearerCheckList(): void {
    this.$bvModal.show("addCheckListTemplatePopover");
  }

  public async onConfirmCheckListTemplateDelete(): Promise<void> {
    await this.deleteCostBearerCheckList();
    this.$bvModal.hide("deleteCheckListTemplatePopover");
  }

  public onDeleteCostBearerCheckListModalPopUp(): void {
    this.$bvModal.show("deleteCheckListTemplatePopover");
  }

  public async onReloadCheckListsAndCheckListTemplates(costBearerId: any): Promise<void> {
    await this.findCheckListsByCostBearerId(costBearerId);
    await this.findAllCheckListTemplates();
  }

  public async onSubmitCheckListTemplate(): Promise<void> {
    if (!this.hasSelectedChecklistTemplate) return;
    this.$bvModal.hide("addCheckListTemplatePopover");
    await this.createChecklistTemplateByCostBearerAction(this.selectedCostBearerId, this.selectedCheckListTemplate.id);

    if (this.createChecklistTemplateByCostBearerSuccess) {
      this.$toasted.success(String(this.$t("messages.save_success")));
      this.selectedCheckListTemplate = "";
    }

    await this.onReloadCheckListsAndCheckListTemplates(this.selectedCostBearerId);
  }

  private get localCheckLists() {
    return this.allCheckListsByCostBearerId.sort((a: any, b: any) => a.checklistTemplateId - b.checklistTemplateId);
  }

  public get checklistItems() {
    return this.localCheckLists[this.activeCheckListTab]?.checklistItems || [];
  }

  private get isLoading() {
    return (
      this.loadingCheckList ||
      this.checkChecklistByCostBearerLoading ||
      this.createChecklistTemplateByCostBearerLoading ||
      this.deleteChecklistTemplateByCostBearerLoading
    );
  }
}
