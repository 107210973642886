




















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FileButton from "@/components/Button/FileButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import UploadButton from "@/components/Button/UploadButton.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import Tinymce from "@/components/Editor/Tinymce.vue";
import { namespace } from "vuex-class";
import { Validations } from "vuelidate-property-decorators";
import Validation from "@/components/Validation.vue";
import { required } from "vuelidate/lib/validators";

const EmailsModule = namespace("emails");
const EmailConfigModule = namespace("email-config");
const EmailTemplatesModule = namespace("email-templates");
const TestingOrganizationsModule = namespace("testing-organizations");

@Component({
  components: {
    FscSimpleCard,
    FscMultiselect,
    FileButton,
    AbortButton,
    UploadButton,
    SendMailButton,
    Tinymce,
    Validation,
  },
})
export default class CostBearersEmailForm extends Vue {
  public name = "CostBearersEmailForm";

  @EmailsModule.Action("sendUserEmail")
  public sendUserEmail: any;

  @EmailConfigModule.Action("findEmailConfigsById")
  public findEmailConfigsByUserId: any;

  @EmailConfigModule.Getter("getDataList")
  public emailConfigs: any;

  @EmailTemplatesModule.Action("findAll")
  public findAllEmailTemplates: any;

  @EmailTemplatesModule.Getter("getDataList")
  public allEmailTemplates: any;

  @EmailsModule.Action("sendEmailWithAttachments")
  public sendEmailWithAttachments: any;

  @EmailsModule.Getter("getIsLoading")
  public emailLoading: any;

  @EmailsModule.Getter("getSuccess")
  public emailSuccess: any;

  @EmailsModule.Action("setBlobFile")
  private setBlobFile: any;

  @EmailsModule.Getter("getBlob")
  private getBlob: any;

  @EmailsModule.Getter("getMimeType")
  private getMimeType: any;

  @TestingOrganizationsModule.Action("filter")
  public findAllTestingOrganizations: any;

  @TestingOrganizationsModule.Getter("getDataList")
  public testingOrganizations: any;

  @Prop()
  public currentUser!: any;

  @Prop()
  public allCostBearers!: any;

  @Prop()
  public emailType!: any;

  @Prop()
  public selectedCostBearer!: any;

  public emailSender: any = {
    email: null,
  };
  public emailSubject = "";
  public emailTemplate: any = {
    name: null,
  };
  public emailBody = "";
  public emailTo: any = [];

  public selectedFiles: any = [];
  public selectedFilesNames: any = [];

  @Validations()
  public validations() {
    return {
      emailSender: {
        email: { required },
      },
    };
  }

  public async mounted() {
    await this.findEmailConfigsByUserId(this.currentUser.id);
    await this.findAllTestingOrganizations({
      resource: "testing-organizations",
      filter: { showArchived: false },
    });
    if (this.emailConfigs.length > 0) {
      this.emailSender = this.emailConfigs[0];
    }
    await this.findAllEmailTemplates({
      resource: "email-templates/",
    });
    if (this.getBlob) {
      this.emailTo = this.selectedCostBearer
        ? [
            {
              id: this.selectedCostBearer.id,
              name: this.selectedCostBearer.name,
              email: this.selectedCostBearer.email,
            },
          ]
        : [];
      let mimeType = this.getMimeType.split("/");
      const myFile = new File([this.getBlob], `Zahlungsdokument.${mimeType[1]}`, {
        type: this.getMimeType,
      });
      this.selectedFiles.push(myFile);
      this.selectedFilesNames.push(`Zahlungsdokument.${mimeType[1]}`);
    }
  }

  public get costBearerEmailsOptions(): Array<any> {
    let allOptions: any = [];
    if (this.allCostBearers && this.allCostBearers.length > 0) {
      this.allCostBearers?.map((costBearer: any) => {
        const email = {
          name: costBearer.name,
          email: costBearer.email,
        };
        allOptions.push(email);
      });
    }
    if (this.testingOrganizations && this.testingOrganizations.length > 0) {
      this.testingOrganizations.map((org: any) => {
        const email = {
          name: org.name ? org.name : "",
          email: org.email ? org.email : "",
        };
        allOptions.push(email);
      });
    }
    return allOptions;
  }

  public onAbortEmailSend() {
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
    this.$emit("abort-email");
  }

  public onSetEmailBody(e: any) {
    this.emailBody = e;
  }

  public onSelectFileUpload(event: any): void {
    this.selectedFilesNames = [];
    let fileArray = Array.from(event.target.files);
    fileArray.map((file: any) => {
      this.selectedFiles.push(file);
    });
    this.selectedFiles.map((file: any) => {
      this.selectedFilesNames.push(file.name);
    });
  }

  protected removeSelectedFile(index: number): void {
    this.selectedFiles.splice(index, 1);
    this.selectedFilesNames.splice(index, 1);
  }

  public async onSubmitSendEmail() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$toasted.error("Du hast keine Absendermailadresse eingetragen!");
      return;
    }

    const receiversArr = this.emailTo
      ? this.emailTo.map((email: any) => {
          if (email.id) {
            return email.email;
          } else {
            return email;
          }
        })
      : "";

    const emails = receiversArr.join();

    if (emails) {
      let formData = new FormData();
      this.selectedFiles.forEach((file: any) => {
        formData.append("files", file);
      });

      const email = {
        emailConfigId: this.emailSender.email ? this.emailSender.id : null,
        to: this.emailTo ? emails : "",
        cc: "",
        subject: this.emailSubject ? this.emailSubject : "",
        body: this.emailBody ? this.emailBody : "",
        repliedEmailId: null,
        repliedAllEmailId: null,
        forwardedEmailId: null,
        emailTemplateId: this.emailTemplate.name ? this.emailTemplate.id : null,
      };
      formData.append("email", JSON.stringify(email));
      await this.sendEmailWithAttachments(formData);
    } else {
      this.$toasted.error("Die eingegebene E-Mail Adresse muss ausgewählt oder mit ‘Enter’ bestätigt werden!");
    }
  }

  @Watch("emailTemplate", { immediate: true, deep: true })
  public onEmailChange(emailTemplate: any) {
    if (emailTemplate) {
      this.emailSubject = emailTemplate.subject;
      this.emailBody = emailTemplate.body;
    }
  }

  @Watch("emailType", { immediate: true, deep: true })
  public onEmailTypeChange(emailType: any) {
    if (emailType) {
      switch (emailType) {
        case 1:
          this.emailTo = this.allCostBearers.filter((cost: any) => cost.id === this.selectedCostBearer.id);
          this.emailTo = this.emailTo[0]?.email ? [this.emailTo[0].email] : "";
          break;
        case 2:
          this.emailTo = this.allCostBearers.filter((cost: any) => cost.email !== "");
          break;
        case 3:
          this.emailTo = [];
          break;
        default:
          return;
      }
    }
  }

  public beforeDestroy() {
    const options = {
      blob: null,
      mimeType: null,
    };
    this.setBlobFile(options);
  }
}
