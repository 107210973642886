






import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CostBearersForm from "@/views/CostBearers/CostBearersForm.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: {
    CostBearersForm,
    FscSimpleCard,
  },
})
export default class CostBearersCreate extends Vue {
  public name = "CostBearersCreate";

  @Prop({ type: Boolean, required: false, default: () => false })
  public loading!: boolean;

  public costBearer = {
    name: "",
    contactPerson: "",
    email: "",
    postalCode: "",
    location: "",
    address: "",
    phone: "",
    fax: "",
    paymentWorkflowId: null,
    invoiceEmail: "",
    info: "",
  };

  public onCostBearerCreate(data: { bearer: any; file: File | null }): void {
    this.$emit("create", data);
  }

  public onCostBearerAbortCreate(): void {
    this.$emit("close-create");
  }
}
