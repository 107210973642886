








































































































































































































































































































































































































































































































import { Component, Prop, Watch, ProvideReactive } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import TimePicker from "@/components/TimePicker.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import { APPOINTMENTS_TYPE, DRIVING_LESSON, OTHER, PRIVATE, THEORY_LESSON, PRACTICAL_EXAM, GQC_OR_GQD } from "@/constants/Appointments";
import { CREATE, EDIT, COPY } from "@/constants/FormType";
import BookButton from "@/components/Button/BookButton.vue";
import { TranslateResult } from "vue-i18n";
import { IStudent } from "@/interfaces/IStudent";
import { IInstructor } from "@/interfaces/IInstructor";
import FscInputNumber from "@/components/Field/FscInputNumber.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { B197, GQC, GQD } from "@/constants/LicenseClasses";
import { GQC_GQD, STANDARD_PRODUCT } from "@/constants/Products";
import { IBasicInstructor } from "@/interfaces/IBasicInstructor";
import _ from "lodash";
import { ALL, NOT_REPEAT, RECURRING_APPOINTMENTS, THIS_AND_FOLLOWING } from "@/constants/RecurringAppointments";
import RecurringModal from "@/views/Scheduler/RecurringModal.vue";
import { ESCORT_VEHICLE, MAIN_VEHICLE, TRAILER } from "@/constants/VehicleUsages";
import { IVehicle } from "@/interfaces/IVehicle";
import UserService from "@/services/UserService";
import ProductRequestMixin from "@/mixins/Request/ProductRequestMixin";
import { mixins } from "vue-class-component";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import VehicleRequestMixin from "@/mixins/Request/VehicleRequestMixin";
import { IBasicStudent } from "@/interfaces/IBasicStudent";
import StudentAppointments from "@/views/Student/Overview/StudentAppointments.vue";
import TrainingContract from "@/views/Student/Info/TrainingContract.vue";
import { PropType } from "vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import SchedulerSignatureModal from "@/views/Scheduler/SchedulerSignatureModal.vue";
import eventBus from "@/plugins/EventBus";
import { IPermission } from "@/interfaces/IPermission";
import moment from "moment";
import SignatureButton from "@/components/Button/SignatureButton.vue";
import { IRecurring } from "@/interfaces/IRecurring";
import FscModal from "@/components/Modal/FscModal.vue";
import { formatStudentName } from "@/utils/NameUtil";
import StudentTimePlannerAppointments from "@/views/Scheduler/StudentTimePlannerAppointments.vue";

const VehicleModule = namespace("vehicle");
const LicenseClassModule = namespace("license-class");
const StudentEducationModule = namespace("student-education");
const AppointmentsModule = namespace("appointments");
const PermissionModule = namespace("permission");
const DocumentModule = namespace("document");

@Component({
  components: {
    TrainingContract,
    StudentAppointments,
    RecurringModal,
    FscSimpleCard,
    FscInputNumber,
    BookButton,
    Datepicker,
    Validation,
    TimePicker,
    FscCard,
    SaveButton,
    AbortButton,
    FormCard,
    RemoveButton,
    CopyButton,
    SchedulerSignatureModal,
    SignatureButton,
    FscModal,
    StudentTimePlannerAppointments,
  },
})
export default class SchedulerForm extends mixins(ProductRequestMixin, StudentRequestMixin, VehicleRequestMixin) {
  public name = "SchedulerForm";

  public date: string | null = null;
  public time: string | null = null;
  public duration: number | undefined = 0;
  public appointmentType = APPOINTMENTS_TYPE[0];
  public instructor: null | IBasicInstructor | any = null;
  public studentModel: { id: number; name: string } | null = null;
  public licenseClass = "";
  public vehicle: null | IVehicle | any = null;
  public escortVehicle: null | IVehicle | any = null;
  public kilometersDriven = null;
  public note: string | null = null;
  public product: any = "";
  public escortVehicleKilometersDriven = null;
  public booked = false;
  public trailer = null;

  public appointmentTitle = null;
  public includeInPayroll = false;
  public includeInInstructorTime = false;

  public isEscortVehicleVisible = false;
  public isTrailerVisible = false;

  public isEscortVehicleRequired = false;
  public isTrailerRequired = false;

  public archived = false;

  public lastClickedItemId = null;

  public defaultDuration: number | undefined = 0;
  public recurringDeleteModal = "recurring-delete-modal";
  public recurringUpdateModal = "recurring-update-modal";
  public recurringUpdateModalShowed = false;
  public messageBeforeRemoveDrivingLesson = "";
  public drivingLessonAppointmentDeleteModal = "driving-lesson-delete-modal";

  public activeTab = 0;

  public drivingLessonSignModal = "driving-lesson-sign-modal";
  public practicalExamSignModal = "practical-exam-sign-modal";

  @Prop({ default: () => [], type: Array })
  public instructors!: Array<any>;

  @VehicleModule.Action("filter")
  public vehicleFilter: any;

  @VehicleModule.Getter("getDataList")
  public vehicles!: Array<IVehicle | any>;

  @VehicleModule.Action("vehicleUsageRequirementsByLicenseClass")
  public vehicleUsageRequirementsByLicenseClass: any;

  @VehicleModule.Getter("getVehicleUsageRequirementsByLicenseClass")
  public getVehicleUsageRequirementsByLicenseClass: any;

  @LicenseClassModule.Action("findAll")
  public findAllLicenceClasses: any;

  @LicenseClassModule.Getter("getDataList")
  public licenseClasses: any;

  @StudentEducationModule.Action("findByStudent")
  public studentEducationFindByStudent: any;

  @StudentEducationModule.Getter("getDataList")
  public studentEducations: any;

  @StudentEducationModule.Action("update")
  public studentEducationArchive: any;

  @StudentEducationModule.Getter("getSuccess")
  public archiveSuccess: any;

  @AppointmentsModule.Action("findByStudentCostBearer")
  public findAppointmentsByStudentId: any;

  @StudentEducationModule.Getter("getDataItem")
  public getStudentEducation: any;

  @AppointmentsModule.Getter("getDataListCostBearer")
  public appointmentsByStudentId: any;

  @PermissionModule.Action("findAll")
  public permissionFindAll: any;

  @PermissionModule.Getter("getDataList")
  public permissions: any;

  @DocumentModule.Action("uploadDocument")
  public uploadDocumentAction: any;

  @Prop()
  public item!: any;

  @Prop()
  public appointmentTypeId!: any;

  @Prop({ default: () => CREATE })
  public type!: string;

  @Prop()
  public instructorInitial!: any;

  @Prop()
  public clickDate!: any;

  @Prop({ type: Array as PropType<Array<IInstructor>> })
  public calendarWriteable!: Array<IInstructor>;

  public disableInput = false;
  public isInitInstructor = true;
  public repeat: string | null = null;
  public repeatEndDate: null | string = null;
  public copyModeIsOn = false;
  public practicalExamUploadUrl = "/practical-exams/{studentPracticalExamId}/upload-student-signature";
  public currentAppointment = 0;
  public submitAndNew: any = null;

  @Prop()
  public minutesNew!: any;
  @Prop()
  public vehiclesNew!: any;
  @Prop()
  public timeNew!: any;
  @Prop()
  public noteNew!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  public signatureDeleteAllowed = false;

  protected get hasPermissionToDeleteService() {
    return this.permissions.find((permission: IPermission) => permission.code === "DELETE_BOOKED_SERVICES");
  }

  public checkPermissionsDeleteBookedService(): boolean {
    if (this.isDrivingLesson && (this.hasNotWritePermission || !this.hasPermissionToDeleteService)) return false;
    return true;
  }

  public openModalAfterCheckedPermissions(): void {
    const result = this.checkPermissionsDeleteBookedService();
    if (!result) {
      this.messageBeforeRemoveDrivingLesson = this.$tc("messages.delete_service_forbidden_message", 1);
      this.$bvModal.show(this.drivingLessonAppointmentDeleteModal);
      return;
    }
    this.appointmentDeletePopover();
  }

  public appointmentDeletePopover(): void {
    if (this.item.recurringAppointmentId) {
      this.$bvModal.show(this.recurringDeleteModal);
    } else {
      this.$bvModal.show("appointmentDeletePopover");
    }
  }

  public appointmentDeletePopoverClose(): void {
    this.$bvModal.hide("appointmentDeletePopover");
  }

  public drivingLessonModalClose(): void {
    this.$bvModal.hide(this.drivingLessonAppointmentDeleteModal);
  }

  public practicalExamSignModalClose(): void {
    this.$bvModal.hide(this.practicalExamSignModal);
  }

  public onDeleteAppointment(eventName = "on-delete"): void {
    if (this.appointmentTypeId && this.item) {
      const appTypeId = this.appointmentTypeId;
      const appId = this.item.id;
      this.$emit(eventName, {
        appointmentTypeId: appTypeId,
        appointmentId: appId,
      });
    }
  }

  public get currentAppointmentId() {
    return this.currentAppointment;
  }

  public get isEditMode(): boolean {
    return this.appointmentTypeId !== 0;
  }

  public get appointmentTypeOption(): Array<any> {
    if (this.typeCreate || this.typeCopy) return APPOINTMENTS_TYPE.filter((t: any) => t.id != PRACTICAL_EXAM && t.id != GQC_OR_GQD);
    return APPOINTMENTS_TYPE;
  }

  public get productOptions(): Array<any> {
    if (!this.studentModel) return [];
    return this.productsByStudentAndLicenseClass;
  }

  public get instructorOptions(): Array<any> {
    if (this.isPracticalExam) {
      return this.instructors.map((instructor: any) => {
        return this.formatInstructor(instructor);
      });
    }
    const currentUser = UserService.getUser();
    return this.instructors
      .filter((instructor: any) => {
        return (
          this.calendarWriteable.findIndex((accessUser: any) => {
            return instructor.id == accessUser.id;
          }) > -1 || currentUser.id == instructor.id
        );
      })
      .map((instructor: any) => {
        return this.formatInstructor(instructor);
      });
  }

  public get studentOption(): Array<any> {
    return this.visibleForCurrentUser.map((student: IStudent) => {
      return this.formatStudent(student);
    });
  }

  public get licenseClassOption(): Array<any> {
    if (!this.studentModel) return [];
    return this.studentEducations.map((education: any) => {
      return education.mainLicenseClass;
    });
  }

  public onSave() {
    if (this.copyModeIsOn) {
      this.onSubmit(true);
      this.copyModeIsOn = false;
    } else {
      if (this.item.isInvoiced && this.isDrivingLesson) {
        this.openInfoModalForInvoicedAppointment();
        return;
      } else if (this.item.booked && this.isDrivingLesson) {
        this.openAppointmentSignatureDeleteModal();
      } else {
        this.onSubmit();
      }
    }
  }

  public openInfoModalForInvoicedAppointment() {
    this.$bvModal.show("appointmentInvoicedNotSavedPopover");
  }

  public onConfirmInvoicedAppointmentNotSaved() {
    this.$bvModal.hide("appointmentInvoicedNotSavedPopover");
  }

  public openAppointmentSignatureDeleteModal() {
    this.$bvModal.show("appointmentConfirmDeleteSignaturePopover");
  }

  public appointmentDeleteSignaturePopoverClose() {
    this.signatureDeleteAllowed = false;
    this.$bvModal.hide("appointmentConfirmDeleteSignaturePopover");
  }

  public onConfirmDeleteSignatureAppointment() {
    this.signatureDeleteAllowed = true;
    this.$bvModal.hide("appointmentConfirmDeleteSignaturePopover");
    this.onSubmit();
  }

  public onSubmit(isNew = false, recurringSelectedOpt: string | null = null): void {
    this.submitAndNew = isNew;
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.$v.$reset();
    const baseData = {
      date: this.date,
      time: this.time,
      instructor: this.instructor,
      note: this.note,
      // defaultDuration: this.item.defaultDuration,
      // defaultDate: this.item.defaultDate,
      // defaultTime: this.item.defaultTime,
    };
    if (this.isDrivingLesson) {
      Object.assign(baseData, {
        duration: this.duration,
        licenseClass: this.licenseClass,
        vehicle: this.vehicle,
        kilometersDriven: this.kilometersDriven,
        student: this.studentModel,
        booked: this.booked,
        escortVehicleKilometersDriven: this.escortVehicleKilometersDriven,
        escortVehicle: this.escortVehicle,
        product: this.product,
        trailer: this.trailer && this.isTrailerVisible ? this.trailer : null,
      });
    } else if (this.isPracticalExam) {
      if (this.typeCreate) {
        this.$toasted.info(this.$tc("calendar.practical_exam_create_message", 1));
        return;
      }

      Object.assign(baseData, {
        id: this.item.id,
        date: this.date,
        time: this.time,
        instructorId: this.instructor.id,
        note: this.note,
        duration: this.duration,
        licenseClass: this.licenseClass,
        vehicleIds: this.vehicle.map((v: any) => v.id),
        kilometersDriven: this.kilometersDriven,
        studentId: this.studentModel?.id,
        booked: this.booked,
        escortVehicleKilometersDriven: this.escortVehicleKilometersDriven,
        escortVehicle: this.escortVehicle,
        trailer: this.trailer,
      });
    } else {
      Object.assign(baseData, {
        durationInMinutes: this.duration,
        includeInPayroll: this.includeInPayroll,
        includeInInstructorTime: this.includeInInstructorTime,
        privateAppointment: this.isPrivate,
        name: this.appointmentTitle,
        vehicle: this.vehicle,
        kilometersDriven: this.kilometersDriven,
      });
    }

    let recurringType: IRecurring | null = null;
    if (this.repeat && this.repeat !== NOT_REPEAT && this.repeatEndDate && this.time) {
      recurringType = {
        repeatEndDate: this.repeatEndDate,
        recurringType: this.repeat,
        timeFrame: [
          {
            startTime: this.time,
            endTime: moment(this.time, "HH:mm:ss").add(this.duration, "minutes").format("HH:mm"),
          },
        ],
      };
    }
    this.$emit("on-submit-form", {
      data: baseData,
      appointmentType: this.appointmentType,
      new: isNew,
      recurringType,
      recurringSelectedOpt,
    });
    this.recurringUpdateModalShowed = false;
  }

  public async onSaveAndNew() {
    await this.onSubmit(true);
    this.$v.$reset();
    this.$emit("copy-mode-active");
  }

  public async onCreateCopy() {
    if (Object.keys(this.item).includes("booked")) {
      this.item.booked = false;
    }
    this.$emit("copy-mode-active");
    this.copyModeIsOn = true;
  }

  public onSignModalClose() {
    eventBus.$emit(this.currentAppointmentId + "-driving-lesson-on-signature-capture-complete", null);
    this.onAbort();
  }

  public async fetchLastUsedVehicleForEducation(educationId: number) {
    this.fetchLastUsedVehicleByStudentEducationAndInstructor({
      educationId,
      instructorId: this.instructor.id,
    }).then((data: any) => {
      this.vehicle = data ? data : null;
      this.trailer = data && data.trailerDto && Object.values(data.trailerDto).length > 0 ? data.trailerDto : null;
      this.escortVehicle = data && data.escortVehicleDto && Object.values(data.escortVehicleDto).length > 0 ? data.escortVehicleDto : null;
    });
  }

  public async mounted(): Promise<void> {
    this.$watch(
      () => [this.studentModel, this.licenseClass],
      () => {
        if (this.studentModel && this.licenseClass) {
          this.fetchProductsByStudentAndLicenseClass(this.studentModel.id, this.licenseClass);
          if (this.licenseClass && !this.isEditMode && this.isDrivingLesson) {
            const education = this.studentEducations.find((education: any) => education.mainLicenseClass === this.licenseClass);
            if (education) {
              this.fetchLastUsedVehicleForEducation(education.id);
            }
          }
        }
        if (this.studentModel) {
          this.studentEducationFindByStudent(this.studentModel.id);
        }
        if (this.item?.id) {
          this.currentAppointment = this.item.id;
        }
      },
      { immediate: true, deep: true }
    );
    await this.permissionFindAll({
      resource: "permissions",
    });

    eventBus.$on(this.currentAppointmentId + "-driving-lesson-on-signature-capture-complete", (data: any) => {
      this.signDrivingLesson(data);
      this.onAbort();
    });
    eventBus.$on(this.currentAppointmentId + "-practical-exam-on-signature-capture-complete", (data: any) => {
      this.signPracticalExam(data);
      this.onAbort();
    });

    this.$root.$on("refresh-student-info", () => {
      const id = this.studentModel?.id;
      if (id) {
        this.findExamAndEducationByStudent(id, false);
      }
    });
  }

  public async signPracticalExam(data: any) {
    if (!data || !data.data) {
      this.practicalExamSignModalClose();
      return;
    }
    if (this.item.id && this.item.id === this.currentAppointmentId) {
      const options = {
        resource: this.practicalExamUploadUrl.replace("{studentPracticalExamId}", this.item.id),
        data: data.data,
      };
      await this.uploadDocumentAction(options);
      this.practicalExamSignModalClose();
    }
  }

  public signDrivingLesson(data: any) {
    this.$emit("booked", data);
    this.$bvModal.hide(this.drivingLessonSignModal);
  }

  public updated() {
    this.$watch(
      () => [
        this.isDisabled,
        this.studentModel,
        this.findAllLicenceClasses,
        this.instructor,
        this.product,
        this.vehicle,
        this.appointmentsByStudentId,
        this.escortVehicle,
        this.trailer,
        // this.instructorOptions,
        // this.studentOption,
      ],
      (val) => val,
      { immediate: true, deep: true }
    );

    /** Remove old created listeners and recreate them **/
    eventBus.$off(this.currentAppointmentId + "-practical-exam-on-signature-capture-complete");
    eventBus.$off(this.currentAppointmentId + "-driving-lesson-on-signature-capture-complete");

    eventBus.$on(this.currentAppointmentId + "-practical-exam-on-signature-capture-complete", (data: any) => {
      this.signPracticalExam(data);
      this.onAbort();
    });
    eventBus.$on(this.currentAppointmentId + "-driving-lesson-on-signature-capture-complete", (data: any) => {
      this.signDrivingLesson(data);
      this.onAbort();
    });
  }

  public beforeUpdate(): void {
    this.lastClickedItemId = this.item.id;
  }

  public async setVehicleFieldsAndOptions(licenseClass: string) {
    if (this.instructor) {
      await this.vehicleUsageRequirementsByLicenseClass(licenseClass);
      const vehicleUsageRequirements: Array<any> = this.getVehicleUsageRequirementsByLicenseClass;

      let escortVehicleRequirement = vehicleUsageRequirements.find((value) => value.vehicleUsage == ESCORT_VEHICLE);
      this.isEscortVehicleVisible = escortVehicleRequirement != null;

      let trailerRequirement = vehicleUsageRequirements.find((value) => value.vehicleUsage == TRAILER);
      this.isTrailerVisible = trailerRequirement != null;

      this.isEscortVehicleRequired = escortVehicleRequirement?.required;
      this.isTrailerRequired = trailerRequirement?.required;

      if (this.instructor && this.instructor.id) {
        // Find vehicles
        await this.fetchVehiclesByInstructorAndUsageAndLicenseClass(this.instructor.id, MAIN_VEHICLE, licenseClass);

        // Find escort vehicles
        await this.fetchEscortVehiclesByInstructorAndUsageAndLicenseClass(this.instructor.id, ESCORT_VEHICLE, licenseClass);

        // Find trailer vehicles
        await this.fetchTrailerVehiclesByInstructorAndUsageAndLicenseClass(this.instructor.id, TRAILER, licenseClass);
      }
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      studentModel: {
        required: requiredIf((val) => {
          return val.isDrivingLesson;
        }),
      },
      licenseClass: {
        required: requiredIf((val) => {
          return val.isDrivingLesson;
        }),
      },
      date: { required },
      time: { required },
      duration: { required },
      appointmentType: { required },
      instructor: { required },
      product: {
        required: requiredIf((val) => {
          return val.isDrivingLesson;
        }),
      },
      vehicle: {
        required: requiredIf((val) => {
          return val.isDrivingLesson && this.licenseClass !== "SON";
        }),
      },
      escortVehicle: {
        required: requiredIf(() => {
          return this.isDrivingLesson ? this.isEscortVehicleRequired && this.licenseClass !== "SON" : false;
        }),
        duplicateVehicle: () => {
          return this.isDrivingLesson ? !(this.vehicle && this.escortVehicle && this.vehicle.id == this.escortVehicle.id) : true;
        },
      },
      trailer: {
        required: requiredIf(() => {
          return this.isDrivingLesson ? this.isTrailerRequired && this.licenseClass !== "SON" : false;
        }),
      },
      repeatEndDate: {
        required: requiredIf((val) => {
          return val && !val.isDrivingLesson && !val.isPracticalExam && val.isNotRepeat;
        }),
      },
      noteComputed: {
        maxValue500: maxLength(500),
      },
    };
  }

  public onAbort(): void {
    this.$emit("abort");
    this.$destroy();
    eventBus.$off(this.currentAppointmentId + "-practical-exam-on-signature-capture-complete");
    eventBus.$off(this.currentAppointmentId + "-driving-lesson-on-signature-capture-complete");
  }

  public get typeCreate(): boolean {
    return this.type === CREATE;
  }

  public get typeEdit(): boolean {
    return this.type === EDIT;
  }

  public get typeCopy(): boolean {
    return this.type === COPY;
  }

  public onBook(): void {
    this.$bvModal.show(this.drivingLessonSignModal);
    // this.$emit("booked");
  }

  public onOpenPracticalExamModal(): void {
    this.$bvModal.show(this.practicalExamSignModal);
  }

  public get tabLabel(): TranslateResult {
    return this.typeCreate ? this.$t("calendar.base_data") : this.$t("general.edit_branch");
  }

  private get isTheoryLesson(): boolean {
    return this.appointmentType.id === THEORY_LESSON;
  }

  private get isPracticalExam(): boolean {
    return this.appointmentType?.id === PRACTICAL_EXAM || this.appointmentTypeId === PRACTICAL_EXAM;
  }

  private get isDrivingLesson(): boolean {
    return this.appointmentType?.id === DRIVING_LESSON || this.appointmentType?.id === GQC_OR_GQD;
  }

  private get isPrivate(): boolean {
    return this.appointmentType?.id === PRIVATE;
  }

  private get isOther(): boolean {
    return this.appointmentType?.id === OTHER;
  }

  public get isDisabled(): boolean {
    return this.hasNotWritePermission && this.typeEdit;
  }

  public get minuteIsDisabled(): boolean {
    return (this.disableInput || this.hasNotWritePermission) && this.typeEdit;
  }

  public formatStudent(student: IStudent): any {
    return { name: formatStudentName(student.firstName, student.lastName), id: student.id };
  }

  public formatInstructor(instructor: IInstructor): any {
    if (!instructor) return null;
    return {
      name: `${instructor.firstName} ${instructor.lastName}`,
      id: instructor.id,
      defaultDrivingHourMinutes: instructor.defaultDrivingHourMinutes,
    };
  }

  protected get getLabel(): TranslateResult {
    return this.appointmentType?.name || "";
  }

  protected onSelectStudent(): void {
    this.licenseClass = "";
    this.product = null;
  }

  protected onSelectLicenseClass(): void {
    this.product = null;
    this.escortVehicle = null;
    this.trailer = null;
  }

  protected onSelectInstructor(): void {
    if (this.isPracticalExam) return;
    if (!this.typeEdit) {
      this.studentModel = null;
      this.licenseClass = "";
      this.product = null;
    }
  }

  @Watch("licenseClass")
  public licenseClassWatcher(): void {
    if (this.licenseClass && this.isDrivingLesson) {
      this.setVehicleFieldsAndOptions(this.licenseClass);
    }
    if (this.lastClickedItemId === this.item.id) {
      this.vehicle = null;
    }
  }

  @Watch("item", { immediate: true, deep: true })
  public init(item: any): void {
    this.vehicle = null;
    this.trailer = null;
    this.escortVehicle = null;
    if (!_.isEmpty(item)) {
      this.date = item.date;
      this.time = item.time;
      if (item?.instructors?.length) {
        if (this.appointmentTypeId === PRIVATE) {
          this.instructor = this.formatInstructor(item.instructors[0]);
        } else {
          this.instructor = item.instructors?.map((ins: any) => this.formatInstructor(ins));
        }
      } else if (item?.instructor) {
        this.instructor = this.formatInstructor(item?.instructor);
      }

      this.note = item.note;
      this.booked = Boolean(item.booked);
      this.vehicle = this.isPracticalExam ? item.vehicles : item.vehicle;
      // determine is driving lesson
      if (Object.prototype.hasOwnProperty.call(item, "licenseClass")) {
        this.licenseClass = item.licenseClass;
        this.kilometersDriven = item.kilometersDriven;
        this.studentModel = item.student ? this.formatStudent(item.student) : null;
        this.escortVehicleKilometersDriven = item.escortVehicleKilometersDriven;
        this.escortVehicle = item.escortVehicle;
        this.product = item.product;
        this.duration = item.duration;
        this.trailer = item.trailer;
      } else {
        this.duration = item.durationInMinutes;
        this.includeInPayroll = item.includeInPayroll;
        this.includeInInstructorTime = item.includeInInstructorTime;
        this.appointmentTitle = item.name;
        this.repeat = item.recurringType || NOT_REPEAT;
        this.repeatEndDate = item.endDate;
        this.kilometersDriven = item?.kilometersDriven;
      }
    }
  }

  @Watch("appointmentTypeId", { immediate: true, deep: true })
  public async appointmentTypeIdWatcher(val: any): Promise<void> {
    if (val) {
      this.appointmentType = await this.appointmentTypeOption.find((x: any) => x.id === val);
    }
  }

  @Watch("productOptions", { immediate: true, deep: true })
  public onAppointmentTypeChange(appointmentType: any) {
    if (this.isDrivingLesson && !this.isEditMode && this.productOptions) {
      this.product = this.productOptions.find((prod: any) => prod.isDefault);
    }
  }

  @Watch("clickDate", { deep: true, immediate: true })
  public clickDateChange(clickDateCtx: any): void {
    // this.setDateTime(clickDateCtx);
    if (clickDateCtx && this.typeCreate) {
      this.setDateTime(clickDateCtx);
      if (this.instructor) {
        this.duration = this.instructor?.defaultDrivingHourMinutes;
      }
    }
  }

  @Watch("instructor")
  protected async onChangeInstructor(instructor: any): Promise<void> {
    if (instructor) {
      await this.findVisibleForCurrentUser();

      await this.vehicleFilter({
        resource: "vehicles",
        filter: { archived: this.archived, instructorId: instructor.id },
      });

      if (this.isDrivingLesson) {
        if (this.licenseClass) {
          await this.setVehicleFieldsAndOptions(this.licenseClass);
        }
      }
      if (!this.duration) {
        this.duration = instructor.defaultDrivingHourMinutes;
        this.defaultDuration = instructor.defaultDrivingHourMinutes;
      }
    }
  }

  @Watch("type")
  protected onChangeType() {
    if (this.typeCreate || this.typeCopy) {
      this.instructor = this.getInstructor(this.instructorInitial.id);
    }
  }

  @Watch("studentEducations")
  public onStudentEducationChange(studentEd: any): void {
    if (studentEd && studentEd.length === 1) {
      this.licenseClass = this.studentEducations[0].mainLicenseClass;
    }
  }

  @Watch("product")
  public onProductsChange(product: any): void {
    if (product && product.standardProductId === STANDARD_PRODUCT && this.licenseClass === B197) {
      this.defaultDuration = this.duration;
      this.duration = 15;
      this.disableInput = true;
    } else if (product && product.standardProductId === GQC_GQD && this.licenseClass === GQC) {
      this.duration = 60;
      this.disableInput = false;
    } else if (product && product.standardProductId === GQC_GQD && this.licenseClass === GQD) {
      this.duration = 60;
      this.disableInput = false;
    } else {
      // this.duration = this.defaultDuration;
      this.disableInput = false;
    }
  }

  @Watch("appointmentType")
  public onChangeAppointmentType(appointment: any) {
    if (appointment && appointment.id !== DRIVING_LESSON && this.defaultDuration) {
      this.duration = this.defaultDuration;
      this.disableInput = false;
    } else if (this.product && this.product.standardProductId === STANDARD_PRODUCT && this.licenseClass === B197) {
      this.duration = 15;
      this.disableInput = true;
    }
  }

  protected setDateTime(clickDate: string): void {
    this.date = clickDate.toString().slice(0, 10);
    this.time = clickDate.toString().slice(11, 16);
  }

  protected getInstructor(id: number): IBasicInstructor {
    const found = this.instructors.find((x: any) => x.id === id);
    return this.formatInstructor(found);
  }

  @Watch("instructorInitial", { immediate: true })
  protected onChangeInstructorInitial(instructor: IInstructor) {
    if (this.isInitInstructor && this.typeCreate && instructor?.id) {
      const calendarWriteAccessUsers = UserService.calendarWriteAccessUsers();
      const currentUser = UserService.getUser();
      if (calendarWriteAccessUsers.some((user: IBasicStudent) => user.id == instructor.id) || currentUser.id == instructor.id) {
        this.instructor = this.getInstructor(instructor.id);
      }
    }
    this.isInitInstructor = false;
  }

  protected get repeatOptions(): Array<any> {
    return RECURRING_APPOINTMENTS;
  }

  private onSelectAppointmentType(): void {
    if (!this.isDrivingLesson) {
      this.repeat = null;
      this.repeatEndDate = null;
    }
  }

  private get isNotRepeat(): boolean {
    return this.repeat !== NOT_REPEAT;
  }

  private get hasRepeat(): boolean {
    return (Boolean(this.item?.recurringType) || this.hasNotWritePermission) && this.typeEdit;
  }

  protected okRecurringDelete(selected: string): void {
    if (selected == ALL) {
      this.onDeleteAppointment("on-delete-all");
    } else if (selected == THIS_AND_FOLLOWING) {
      this.onDeleteAppointment("on-delete-future");
    } else {
      this.onDeleteAppointment();
    }
  }

  protected okRecurringUpdate(selected: string): void {
    this.recurringUpdateModalShowed = true;
    if (selected == ALL) {
      this.onSubmit(false, ALL);
    } else if (selected == THIS_AND_FOLLOWING) {
      this.onSubmit(false, THIS_AND_FOLLOWING);
    } else {
      this.onSubmit();
    }
  }

  @Watch("studentModel", { deep: true, immediate: true })
  public onStudentSelectChange(studentModel: any) {
    if (studentModel) {
      this.findExamAndEducationByStudent(studentModel.id, false);
      this.findAppointmentsByStudentId(studentModel.id);
      this.fetchStudent(studentModel.id);
    }
  }

  @ProvideReactive("selectedStudent")
  private get selectedRowReactive(): any {
    return this.student;
  }

  public changeTab(index: number) {
    this.activeTab = index;
  }

  protected cancelRecurringUpdate(): void {
    this.recurringUpdateModalShowed = false;
  }

  protected get vehicleOptions(): Array<any> {
    if (!this.isDrivingLesson) return this.vehicles;
    else if (!this.licenseClass && this.isDrivingLesson) return [];
    return this.vehiclesByInstructorAndUsageAndLicenseClass
      .filter((v: IVehicle) => v.id !== this.escortVehicle?.id)
      .map((vehicle: any) => ({ id: vehicle.id, brandAndModel: vehicle.brandAndModel }));
  }

  protected get escortVehicleOptions(): Array<IVehicle> {
    if (!this.licenseClass && this.isDrivingLesson) return [];
    return this.escortVehiclesByInstructorAndUsageAndLicenseClass.filter((v: IVehicle) => v.id !== this.vehicle?.id);
  }

  protected get trailerOptions(): Array<IVehicle> {
    if (!this.licenseClass && this.isDrivingLesson) return [];
    return this.trailerVehiclesByInstructorAndUsageAndLicenseClass;
  }

  protected get hasNotWritePermission(): boolean {
    return !this.$can(this.MODULE_TIME_PLANNER_WRITE);
  }

  protected get showRepeatFields(): boolean {
    return (
      (!this.isDrivingLesson && !this.isPracticalExam && this.typeEdit && this.item.recurringAppointmentId) ||
      (!this.isDrivingLesson && !this.isPracticalExam && this.typeCreate) ||
      (!this.isDrivingLesson && !this.isPracticalExam && this.typeCopy)
    );
  }

  public async onArchiveEducation(selectedEducation: any): Promise<void> {
    if (selectedEducation) {
      const educationId = selectedEducation.studentEducationId;
      await this.studentEducationArchive({
        id: educationId,
        resource: "student-educations/archive",
      });
      if (this.studentModel) {
        await this.findExamAndEducationByStudent(this.studentModel.id, false);
      }
    }
  }

  @Watch("minutesNew")
  public onMinutesNewChange(minutes: any) {
    if (minutes) {
      this.duration = minutes;
    }
  }

  @Watch("vehiclesNew")
  public onVehiclesNewChange(vehicles: any) {
    if (vehicles) {
      this.vehicle = vehicles;
    }
  }

  @Watch("timeNew")
  public onTimeNewChange(time: any) {
    if (time) {
      this.time = time;
    }
  }

  @Watch("noteNew")
  public onNoteNewChange(note: any) {
    if (note) {
      this.note = note;
    }
  }

  public get noteComputed() {
    return this.note;
  }

  public set noteComputed(value: string | null) {
    this.note = value?.replace(/\n/g, "") || null;
  }
}
